/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable, NgZone, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ComponentEffects,
  DevicePlatform,
  logCatchError,
} from '@mhe/reader/common';
import {
  AnnotationData,
  ApiAnnotation,
  DocTransform,
  isValidAnnotation,
  ReaderAlert,
  SpineItem,
  ApiSpine,
} from '@mhe/reader/models';
import { Actions, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { addMinutes, format } from 'date-fns';
import {
  combineLatest,
  EMPTY,
  forkJoin,
  from,
  merge,
  Observable,
  of,
} from 'rxjs';
import {
  concatMap,
  exhaustMap,
  filter,
  finalize,
  first,
  map,
  mapTo,
  mergeMap,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { AnnotationsService } from '@mhe/reader/core/reader-api/annotations.service';
import { annotateSelectionOnMobile } from '../components/reader/state/epub-controls.actions';
import { ReaderStore } from '../components/reader/state/reader.store';
import * as analyticsActions from '../components/reader/state/analytics.actions';
import * as readerActions from '../components/reader/state/reader.actions';
import * as toolbarActions from '../components/reader/state/toolbar.actions';
import { MediatorUtils } from './mediator-utils';
import { SpinesService } from '@mhe/reader/core/reader-api/spines.service';
import { EpubViewerStore } from '@mhe/reader/components/epub-viewer';
import * as epubViewerActions from '@mhe/reader/components/epub-viewer/state/epub-viewer.actions';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import {
  AnnotationsContextMenuConfig,
  AnnotationsContextMenuStore,
  AnnotationsOverlayService,
} from '@mhe/reader/components/annotations-context-menu';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import {
  ConfirmationModalComponent,
  ConfirmationModalData,
} from '@mhe/reader/components/modals/confirmation-modal';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import {
  EpubLibCFIService,
  HighlighterService,
} from '@mhe/reader/features/annotation';
import * as annotationsQuery from '@mhe/reader/global-store/annotations/annotations.selectors';
import * as annotationsActions from '@mhe/reader/global-store/annotations/annotations.actions';
// eslint-disable-next-line max-len
import * as highlightListActions from '@mhe/reader/components/annotation-lists/highlight-list/state/highlight-list.actions';
import { HighlightListStore } from '../components/annotation-lists';
import { LiveAnnouncer } from '@angular/cdk/a11y';

// type alias
type annotationdialog = [
  Partial<ApiAnnotation>,
  boolean,
  Range,
  HTMLIFrameElement,
  AnnotationsContextMenuConfig,

];

type annotationHighlightMetadata = [
  () => Observable<annotationdialog>, // map to dialog data
  (annotation: ApiAnnotation) => Observable<ApiAnnotation>, // updates
  (() => void)?, // optional callback
];

@Injectable()
export class AnnotationsMediator extends ComponentEffects {
  private readonly transformActions$ = this.transformStore.actions$;
  private readonly epubViewerActions$ = this.epubViewerStore.actions$;
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly navigationActions$ = this.navigationStore.actions$;
  private unlisteners: Record<string, () => void> = {};

  constructor(
    private readonly actions$: Actions,
    private readonly annotationsOverlayService: AnnotationsOverlayService,
    private readonly annotationsService: AnnotationsService,
    private readonly annotationStore: AnnotationsContextMenuStore,
    private readonly dialog: MatDialog,
    private readonly epubLibCfiService: EpubLibCFIService,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly ga: GoogleAnalyticsService,
    private readonly highlighterService: HighlighterService,
    private readonly mediatorUtils: MediatorUtils,
    private readonly ngZone: NgZone,
    private readonly readerStore: ReaderStore,
    private readonly renderer: Renderer2,
    private readonly store: Store,
    private readonly transformStore: TransformStore,
    private readonly translate: TranslateService,
    private readonly navigationStore: NavigationStore,
    private readonly spineService: SpinesService,
    private readonly highlightListStore: HighlightListStore,
    private readonly liveAnnouncer: LiveAnnouncer,
  ) {
    super();
  }

  private readonly withRelevantIFrameAndSpineItemFromAnnotation = <
    T extends AnnotationData & ApiAnnotation,
  >(
    source$: Observable<T>,
  ): Observable<[T, HTMLIFrameElement, SpineItem]> =>
    source$.pipe(
      withLatestFrom(
        this.readerStore.isDoubleSpread$,
        this.epubViewerStore.albumMode$,
        this.readerStore.doubleSpineItem$,
        this.readerStore.spineItem$,
        this.mediatorUtils.iframes$,
      ),
      map(
        ([
          annotation,
          isDoubleSpread,
          albumMode,
          doubleSpineItem,
          spineItem,
          [cloIframe, leftIframe, rightIframe],
        ]): [T, HTMLIFrameElement, SpineItem] => {
          const parsedCfi = this.epubLibCfiService.parseCFI(annotation.cfi);
          const ids = isDoubleSpread && !albumMode;

          if (ids && parsedCfi.spineIndex === doubleSpineItem?.left?.index) {
            return [annotation, leftIframe, doubleSpineItem.left];
          } else if (
            ids &&
            parsedCfi.spineIndex === doubleSpineItem?.right?.index
          ) {
            return [annotation, rightIframe, doubleSpineItem.right];
          }

          return [annotation, cloIframe, spineItem as SpineItem];
        },
      ),
    );

  private readonly filterContextMenuEnabled = <T>(
    source$: Observable<T>,
  ): Observable<T> => {
    return source$.pipe(
      withLatestFrom(this.readerStore.annotationsContextMenuConfig$),
      filter(([_source, config]) => {
        const { placemarks, notes, highlights, readspeaker, isAiAssistOffered } = config;
        return [highlights, notes, placemarks, readspeaker, isAiAssistOffered].some((c) => !!c);
      }),
      map(([source]) => source),
    );
  };

  /** navigation */

  private readonly annotationsInit$ = this.effect(() => {
    const { fetchDistinctSpineIds } = annotationsActions;
    return this.readerActions$.pipe(
      ofType(readerActions.initComplete),
      tap(() => this.store.dispatch(fetchDistinctSpineIds())),
    );
  });

  private readonly annotationsOnFetchDistinctSpineIdsSuccess$ = this.effect(
    () =>
      this.actions$.pipe(
        ofType(annotationsActions.fetchDistinctSpineIdsSuccess),
        map(() =>
          annotationsActions.fetchAnnotationBySpineId({
            spineId: window.location.pathname.split('/')[3],
          }),
        ),
        tap((action: Action) => this.store.dispatch(action)),
      ),
  );

  private readonly fetchAnnotationsForCurrentPage$ = this.effect(() => {
    const renderSingle = this.epubViewerStore.actions$.pipe(
      ofType(epubViewerActions.renderSinglePane),
      map(({ spineItem }) => [spineItem.id]),
    );
    const renderDouble = this.epubViewerStore.actions$.pipe(
      ofType(epubViewerActions.renderDoublePane),
      map(({ doubleSpineItem }) => [
        doubleSpineItem.left?.id,
        doubleSpineItem.right?.id,
      ]),
    );
    return merge(renderSingle, renderDouble).pipe(
      tap((spineIds: string[]) =>
        spineIds.forEach((spineId) =>
          this.store.dispatch(
            annotationsActions.fetchAnnotationBySpineId({ spineId }),
          ),
        ),
      ),
    );
  });

  private readonly updateSpineStatus$ = this.effect(() =>
    this.actions$.pipe(
      ofType(annotationsActions.addAnnotationsBySpineId),
      tap(({ spineId }) =>
        this.store.dispatch(annotationsActions.updateSpinesStatus({ spineId })),
      ),
      logCatchError('updateSpineStatus$'),
    ),
  );

  private readonly fetchAnnotationsBySpineId$ = this.effect(() => {
    return this.actions$.pipe(
      ofType(annotationsActions.fetchAnnotationBySpineId),
      map(({ spineId }) => spineId),
      tap(spineID =>
        this.highlightListStore.dispatch(
          highlightListActions.askForAnnotations({ spineID }),
        ),
      ),
      logCatchError('fetchAnnotationsBySpineId$'),
    );
  });

  private readonly fetchAnnotationsForAllSpines$ = this.effect(() => {
    return this.actions$.pipe(
      ofType(annotationsActions.fetchAnnotationsAllSpines),
      withLatestFrom(this.store.select(annotationsQuery.getSpineIds)),
      concatMap(([, spines]) => spines),
      withLatestFrom(
        this.mediatorUtils.readerApi$,
        this.mediatorUtils.requestContext$,
      ),
      mergeMap(([spineId, api, context]) =>
        this.annotationsService
          .getAnnotationsForEpub(api, { ...context, spineId })
          .pipe(
            map(({ items: annotations }) =>
              annotationsActions.addAnnotationsBySpineId({
                annotations,
                spineId,
                loaded: true,
              }),
            ),
          ),
      ),
      tap((action: Action) => this.store.dispatch(action)),
      logCatchError('fetchAnnotationsForAllSpines$'),
    );
  });

  private readonly beginAnnotationExport$ = this.effect(() => {
    return this.actions$.pipe(
      ofType(annotationsActions.fetchAnnotationsForAllSpinesByType),
      tap(() => this.store.dispatch(annotationsActions.startExport())),
      logCatchError('beginAnnotationExport$'),
    );
  });

  private readonly fetchAnnotationForAllSpinesByType$ = this.effect(() => {
    return this.actions$.pipe(
      ofType(annotationsActions.fetchAnnotationsForAllSpinesByType),
      withLatestFrom(
        this.mediatorUtils.readerApi$,
        this.mediatorUtils.requestContext$,
      ),
      mergeMap(([annotationData, api, context]) =>
        this.annotationsService
          .getAnnotationsForEpub(
            api,
            { ...context },
            annotationData.annotationType,
          )
          .pipe(
            map(({ items: annotations }) =>
              annotationsActions.addAnnotations({
                annotations,
                loaded: true,
              }),
            ),
          ),
      ),
      tap((action: Action) => this.store.dispatch(action)),
      tap(() => this.store.dispatch(annotationsActions.exportSuccess())),
      logCatchError('fetchAnnotationForAllSpinesByType$'),
    );
  });

  private readonly fetchSpineIds$ = this.effect(() =>
    this.actions$.pipe(
      ofType(annotationsActions.fetchDistinctSpineIds),
      withLatestFrom(
        this.mediatorUtils.readerApi$,
        this.mediatorUtils.requestContext$,
        this.readerStore.flatToc$.pipe(filter((x) => x !== undefined)),
        this.readerStore.spine$,
      ),
      switchMap(([, api, context, flatTocDict, bookSpines]) => {
        return this.spineService
          .getSpines(api, context)
          .pipe(
            map((spines: ApiSpine[]): ApiSpine[] => {
              const flatToc = Object.values(flatTocDict);
              return spines.map((spine) => {
                const spineIndex =
                  this.mediatorUtils.getSpineIndexFromSpineId(
                    flatToc,
                    spine.spineID,
                    bookSpines as SpineItem[],
                  );

                return spineIndex?.label
                  ? { ...spine, spineDataRequested: false, groupLabel: spineIndex?.label }
                  : { ...spine, spineDataRequested: false };
              });
            }),
          )
          .pipe(
            map((spines) =>
              annotationsActions.fetchDistinctSpineIdsSuccess({ spines }),
            ),
          );
      }),
      tap((action: Action) => this.store.dispatch(action)),
    ),
  );

  private readonly showAnnotateOnMobile = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.rangeSelected),
      this.filterContextMenuEnabled,
      filter(({ devicePlatform }) => devicePlatform !== DevicePlatform.Desktop),
      tap(() => this.readerStore.setShowAnnotateButton(true)),
    ),
  );

  private readonly hideAnnotateOnMobile = this.effect(() =>
    merge(
      this.epubViewerActions$.pipe(
        ofType(
          epubViewerActions.renderSinglePane,
          epubViewerActions.renderDoublePane,
        ),
      ),
      this.transformActions$.pipe(ofType(transformActions.rangeRemoved)),
    ).pipe(tap(() => this.readerStore.setShowAnnotateButton(false))),
  );

  private readonly newAnnotation$ = this.effect(() => {
    const { annotationsContextMenuConfig$, quickAnnotationEnabled$ } =
      this.readerStore;
    const { requestContext$ } = this.mediatorUtils;
    const { highlightColorAndShape$ } = this.annotationStore;

    const newAnnotationConfig$ = combineLatest([
      annotationsContextMenuConfig$,
      quickAnnotationEnabled$,
    ]).pipe(
      map(([config, quickAnnotation]) => {
        if (quickAnnotation) {
          config = {
            ...config,
            highlights: false,
            placemarks: false,
            notes: false,
          };
        }

        return config;
      }),
    );

    return this.transformActions$.pipe(
      ofType(transformActions.rangeSelected),
      this.filterContextMenuEnabled,
      this.mediatorUtils.withRelevantSpineItemAndIFrameFromSelection,
      withLatestFrom(
        newAnnotationConfig$,
        quickAnnotationEnabled$,
        requestContext$,
        highlightColorAndShape$,
      ),
      tap(
        ([
          [{ selection, devicePlatform }, iframe, spineItem],
          config,
          quickAnnotation,
          requestContext,
          highlightColorShape,
        ]) => {
          const mapDialogData = () => {
            const doc = iframe.contentDocument as Document;
            const selectionRange = selection.getRangeAt(0);
            const y = selectionRange.getBoundingClientRect().bottom;
            const iframeHeight = iframe.getBoundingClientRect().height;
            const showAbove = y > iframeHeight / 2;
            const baseCFI = this.epubLibCfiService.generateBasePath(
              spineItem.index,
              spineItem.id,
            );
            const resourceUUID = uuid();

            const quickAnnotationSeed = quickAnnotation
              ? {
                highlight: true,
                shape: highlightColorShape.shape,
                color: highlightColorShape.color,
              }
              : {};

            const fromSelection = this.highlighterService.createFromSelection(
              doc,
              baseCFI,
            );
            if (!fromSelection) {
              console.warn('Invalid selection.');
              return EMPTY;
            }

            const annotationSeed = {
              resourceUUID,
              ...fromSelection,
              ...requestContext,
              spineID: spineItem?.id,
              ...quickAnnotationSeed,
            };

            const data: annotationdialog = [
              annotationSeed as Partial<ApiAnnotation>,
              showAbove,
              selectionRange,
              iframe,
              config,
            ];
            const desktopTrigger$ = of('trigger now');
            const mobileTrigger$ = this.readerActions$.pipe(
              ofType(annotateSelectionOnMobile),
            );

            // On mobile devices, the context overlay is shown after a button is clicked.  On the web,
            //   it should be shown immediately after highlighting some text.
            const trigger$: Observable<any> =
              devicePlatform === DevicePlatform.Desktop
                ? desktopTrigger$
                : mobileTrigger$;

            const data$ = trigger$.pipe(mapTo(data));
            return data$;
          };

          const saveContextAnnotation = (annotation: ApiAnnotation) => {
            const save$ = this.saveAnnotation(annotation);
            return isValidAnnotation(annotation) ? save$ : EMPTY;
          };

          const onDialogClose = () => {
            selection.removeAllRanges();
            this.readerStore.setShowAnnotateButton(false);
          };

          const annotationMetadata: annotationHighlightMetadata = [
            mapDialogData,
            saveContextAnnotation,
            onDialogClose,
          ];

          quickAnnotation
            ? this.quickAnnotation$(annotationMetadata)
            : this.openContextMenu$(annotationMetadata);
        },
      ),
      logCatchError('newAnnotation$'),
    );
  });

  readonly existingAnnotation$ = this.effect(
    (annotationId$: Observable<string>) => {
      return annotationId$.pipe(
        this.filterContextMenuEnabled,
        switchMap((annotationId) =>
          this.store.pipe(
            select(annotationsQuery.getAnnotationById, { id: annotationId }),
            first(),
            // this is related to the comment in mapAnnotationUpsert
            map((annotation) =>
              annotation?.highlight
                ? annotation
                : { ...annotation, color: undefined },
            ),
          ),
        ),
        this.withRelevantIFrameAndSpineItemFromAnnotation,
        withLatestFrom(this.readerStore.annotationsContextMenuConfig$),
        tap(
          ([[annotation, iframe, _spineItem], config]: [
            [ApiAnnotation, HTMLIFrameElement, SpineItem],
            AnnotationsContextMenuConfig,
          ]) => {
            const orgAnnotation = { ...annotation };

            const mapDialogData = () => {
              const doc = iframe.contentDocument as Document;
              const mark = doc.querySelector(
                `mark[data-highlight-id="${annotation.resourceUUID}"]`,
              ) as Element;
              const iframeHeight = iframe.getBoundingClientRect().height;
              const showAbove =
                mark.getBoundingClientRect().y > iframeHeight / 2;

              this.highlighterService.removeAnnotation(
                fromApiAnnotationToAnnotationData(annotation) as AnnotationData,
                doc,
              );
              this.store.dispatch(
                annotationsActions.removeAnnotation({ annotation }),
              );

              const selectionRange = this.epubLibCfiService.getRangeFromCFI(
                annotation.cfi,
                doc,
              );

              const data: annotationdialog = [
                annotation,
                showAbove,
                selectionRange,
                iframe,
                config,
              ];

              return of(data);
            };

            this.openContextMenu$([
              mapDialogData,
              (anno: ApiAnnotation) => {
                const valid = isValidAnnotation(anno);
                return valid
                  ? this.saveAnnotation(anno, orgAnnotation)
                  : this.deleteAnnotation(orgAnnotation);
              },
            ]);
          },
        ),
        logCatchError('existingAnnotation$'),
      );
    },
  );

  private readonly quickAnnotation$ = this.effect(
    (dialogLaunch$: Observable<annotationHighlightMetadata>) => {
      return dialogLaunch$.pipe(
        switchMap(([annotationData, saveFn, callback]) => {
          return annotationData().pipe(
            map(([annotation]) => mapAnnotationUpsert(annotation)),
            switchMap(saveFn),
            finalize(() => {
              this.ga.event({
                eventCategory: 'Reader',
                eventAction: 'Quick Annotation',
              });
              callback?.();
            }),
          );
        }),
        logCatchError('quickAnnotation$'),
      );
    },
  );

  private readonly openContextMenu$ = this.effect(
    (dialogLaunch$: Observable<annotationHighlightMetadata>) => {
      return dialogLaunch$.pipe(
        // With the introduction of the highlight button for
        // mobile, mutliple selections that trigger openContextMenu$
        // can be emitted before the user opens the menu, so here
        // we want to switchMap.
        switchMap(([mapdata, updates, callback]) => {
          return mapdata().pipe(
            exhaustMap((data) => {
              return this.annotationsOverlayService
                .openContextMenu(...data)
                .pipe(
                  map((annotation) => mapAnnotationUpsert(annotation)),
                  switchMap(updates),
                  finalize(() => {
                    callback?.();
                  }),
                );
            }),
          );
        }),
        logCatchError('openContextMenu$'),
      );
    },
  );

  readonly inFlightAnnotation$ = this.effect(() =>
    this.annotationStore.annotation$.pipe(
      map(fromApiAnnotationToAnnotationData),
      startWith(),
      pairwise(),
      tap(([previousAnnotation, annotation]) => {
        if (previousAnnotation) {
          this._removeAnnotationHighlight$(previousAnnotation);
        }

        if (annotation) {
          this._renderAnnotationHighlight$(annotation);
        }
      }),
      logCatchError('inFlightAnnotation$'),
    ),
  );

  readonly annotationsPerSpineLiveTransformer$ = this.effect(() =>
    this.epubViewerActions$.pipe(
      ofType(epubViewerActions.renderComplete),
      map((action) => action.dt),
      withLatestFrom(
        this.readerStore.spineItem$,
        this.readerStore.doubleSpineItem$,
      ),
      mergeMap(
        ([dt, spineItem, doubleSpineItem]): Observable<ApiAnnotation[]> => {
          // Cleanup subscriptions when navigating away from the current spine.
          const cleanup$ = merge(
            this.epubViewerActions$.pipe(
              ofType(epubViewerActions.renderSinglePane),
              filter((action) => action.spineItem !== spineItem),
            ),
            this.epubViewerActions$.pipe(
              ofType(epubViewerActions.renderDoublePane),
              filter((action) => action.doubleSpineItem !== doubleSpineItem),
            ),
            this.navigationActions$.pipe(
              ofType(navigationActions.forceRefreshSpineItem),
            ),
          );
          return merge(
            this.storedAnnotations(dt, cleanup$),
            this.deletedAnnotations(cleanup$),
          ).pipe(finalize(() => this.unlisten()));
        },
      ),
      logCatchError('annotationsPerSpineLiveTransformer$'),
    ),
  );

  // This pulls annotations from the store and paints them to the iframes.  It also sets up event listeners to
  //   modify exisitng annotations
  private storedAnnotations(
    dt: DocTransform,
    cleanup$: Observable<any>,
  ): Observable<any> {
    return this.store.pipe(
      select(annotationsQuery.getAnnotationsBySpineId, {
        spineId: dt.spineItem?.id,
      }),
      startWith([]),
      pairwise(),
      tap(([previousAnnotations, annotations]) => {
        this.unlisten();
        previousAnnotations.forEach((a) => {
          const annotationData = fromApiAnnotationToAnnotationData(
            a,
          ) as AnnotationData;
          this._removeAnnotationHighlight$(annotationData);
        });
        annotations.forEach((a) => {
          const annotationData = fromApiAnnotationToAnnotationData(
            a,
          ) as AnnotationData;
          this._renderAnnotationHighlight$(annotationData);
        });
        this.setupAnnotationClickHandlers(dt.doc);
      }),
      takeUntil(cleanup$),
    );
  }

  // This removes the annotations deleted from the store from the iframes
  private deletedAnnotations(cleanup$: Observable<unknown>) {
    return this.actions$.pipe(
      ofType(annotationsActions.removeAnnotation),
      map(({ annotation }) => fromApiAnnotationToAnnotationData(annotation)),
      tap((annotation: AnnotationData) =>
        this._removeAnnotationHighlight$(annotation),
      ),
      tap((annotation: AnnotationData) => this.unlisten(annotation.id)),
      takeUntil(cleanup$),
    );
  }

  private readonly _removeAnnotationHighlight$ = this.effect(
    (annotation$: Observable<AnnotationData>) =>
      annotation$.pipe(
        this.withRelevantIFrameAndSpineItemFromAnnotation,
        tap(([annotation, iframe]) => {
          const doc = iframe.contentDocument as Document;
          this.highlighterService.removeAnnotation(annotation, doc);
        }),
        logCatchError('_removeAnnotationHighlight$'),
      ),
  );

  private readonly _renderAnnotationHighlight$ = this.effect(
    (annotation$: Observable<AnnotationData>) =>
      annotation$.pipe(
        this.withRelevantIFrameAndSpineItemFromAnnotation,
        tap(([annotation, iframe]) => {
          const doc = iframe.contentDocument as Document;
          if (isValidAnnotation(annotation)) {
            this.highlighterService.addAnnotation(annotation, doc);
          }
        }),
        logCatchError('_renderAnnotationHighlight$'),
      ),
  );

  private saveAnnotation(
    annotation: ApiAnnotation,
    orgAnnotation?: ApiAnnotation,
  ): Observable<ApiAnnotation> {
    return of(annotation).pipe(
      withLatestFrom(this.mediatorUtils.readerApi$),
      mergeMap(([_, readerApi]) => {
        const alert: ReaderAlert = {
          alertType: 'alert-error',
          translateKey: 'shared.generic_save_error',
        };

        return this.annotationsService
          .saveAnnotation(readerApi, annotation)
          .pipe(
            tap(() => this.readerStore.removeAlert(alert.translateKey)),
            this.mediatorUtils.catchErrorAlert(alert, {
              callback: () => {
                const annotationData =
                  fromApiAnnotationToAnnotationData(annotation);
                this._removeAnnotationHighlight$(
                  annotationData as AnnotationData,
                );

                if (orgAnnotation) {
                  const addAnnotationAction = annotationsActions.addAnnotation({
                    annotation: orgAnnotation,
                  });
                  this.store.dispatch(addAnnotationAction);
                }
              },
            }),
          );
      }),
      tap((upsertedAnnotation) => {
        this.annotationStore.reset();

        const { addAnnotation } = annotationsActions;
        const { trackAnnotationChagnes } = analyticsActions;

        this.readerStore.dispatch(
          trackAnnotationChagnes({ annotation, prevAnnotation: orgAnnotation }),
        );
        this.store.dispatch(addAnnotation({ annotation: upsertedAnnotation }));
      }),
      map((upsertedAnnotation) => upsertedAnnotation),
    );
  }

  private deleteAnnotation(
    annotation: ApiAnnotation,
  ): Observable<ApiAnnotation> {
    return of(annotation).pipe(
      withLatestFrom(this.mediatorUtils.readerApi$),
      mergeMap(([_, readerApi]) => {
        const alert: ReaderAlert = {
          alertType: 'alert-error',
          translateKey: 'shared.generic_save_error',
        };

        return this.annotationsService
          .deleteAnnotation(readerApi, annotation.resourceUUID)
          .pipe(
            tap(() => {
              let annotationTextAnnouncement: string;
              const annotationType: 'highlight' | 'note' | 'placemark' =
                annotation.placemarkText ? 'placemark' : annotation.note ? 'note' : 'highlight';
              switch (annotationType) {
                case 'highlight':
                  annotationTextAnnouncement = 'context.remove_annotation_highlight';
                  break;
                case 'placemark':
                  annotationTextAnnouncement = 'context.remove_annotation_placemark';
                  break;
                case 'note':
                  annotationTextAnnouncement = 'context.remove_annotation_note';
                  break;
                default:
                  annotationTextAnnouncement = 'context.remove_annotation';
              }
              const translatedText = this.translate.instant(annotationTextAnnouncement);
              void this.liveAnnouncer.announce(translatedText, 'polite');
            }),
            tap(() => this.readerStore.removeAlert(alert.translateKey)),
            tap(() =>
              this.readerStore.dispatch(
                analyticsActions.trackAnnotationDeleted({ annotation }),
              ),
            ),
            this.mediatorUtils.catchErrorAlert(alert, {
              callback: () => {
                const addAnnotationAction = annotationsActions.addAnnotation({
                  annotation,
                });
                this.store.dispatch(addAnnotationAction);
              },
            }),
          );
      }),
      tap(() => this.annotationStore.reset()),
      mapTo(annotation),
    );
  }

  private setupAnnotationClickHandlers(doc: Document) {
    doc
      .querySelectorAll('mark[class*="clickable"][data-highlight-id]')
      .forEach((element) => {
        const id = element.getAttribute('data-highlight-id');
        const unlistener = this.renderer.listen(
          element,
          'click',
          (event: MouseEvent) => {
            // do not open annotation-menu when contained inside <a>
            const target = event.target as HTMLElement;
            if (isInsideLink(target)) return;

            // open annotation-menu
            event.stopPropagation();
            this.ngZone.run(() => {
              this.existingAnnotation$(id as string);
            });
          },
        );

        this.unlisteners = {
          ...this.unlisteners,
          [id as string]: unlistener,
        };
      });
  }

  private unlisten(id?: string) {
    const { [id as string]: specifiedUnlistener, ...theRest } =
      this.unlisteners;

    if (!!id && !!specifiedUnlistener) {
      specifiedUnlistener();
      this.unlisteners = { ...theRest };
    } else if (!id && !specifiedUnlistener) {
      Object.values(this.unlisteners).forEach((ul) => ul());
      this.unlisteners = {};
    }
  }

  /** batch deletes */
  private readonly _confirmDeletePageAnnotations$ = this.effect(() => {
    const { confirmDeleteAllAnnotations, confirmDeletePageAnnotations } =
      toolbarActions;

    return this.readerActions$.pipe(
      ofType(confirmDeleteAllAnnotations, confirmDeletePageAnnotations),
      map(({ type }) => type === confirmDeleteAllAnnotations.type),
      exhaustMap((isAll) => {
        const action = isAll
          ? toolbarActions.deleteAllAnnotations()
          : toolbarActions.deletePageAnnotations();
        const dialog$ = this.batchDeleteConfirmationDialog(isAll).afterClosed();

        return dialog$.pipe(
          filter((rsp) => Boolean(rsp)),
          tap(() => this.readerStore.dispatch(action)),
        );
      }),
      logCatchError('_confirmDeletePageAnnotations$'),
    );
  });

  private readonly _deletePageAnnotations$ = this.effect(() => {
    const { spineItem$, doubleSpineItem$ } = this.readerStore;
    const { withLatestDoubleSpread, readerApi$ } = this.mediatorUtils;
    const { getAnnotationsBySpineId } = annotationsQuery;

    const spineAnnotations$ = (ids: string[]) => {
      const annotations$ = ids.map((spineId) => {
        const selectSpineAnnotations = select(getAnnotationsBySpineId, {
          spineId,
        });
        return this.store.pipe(selectSpineAnnotations);
      });

      return combineLatest([...annotations$]).pipe(
        map(
          (annotations) => annotations.reduce((acc, val) => acc.concat(val)),
          [],
        ),
        take(1),
      );
    };

    return this.readerActions$.pipe(
      ofType(toolbarActions.deletePageAnnotations),
      withLatestFrom(spineItem$, doubleSpineItem$),
      withLatestDoubleSpread,
      map(([[_, spineItem, doubleSpineItem], ids]) => {
        const spines = ids
          ? [doubleSpineItem?.left, doubleSpineItem?.right]
          : [spineItem];
        return spines.map(({ id, index }: SpineItem) => ({
          spineId: id,
          spineLocation: index,
        }));
      }),
      mergeMap((spineData) => {
        const ids = spineData.map(({ spineId }) => spineId);
        const annotations$ = spineAnnotations$(ids);

        const deletereqs$ = annotations$.pipe(
          switchMap((annotations) => from(annotations)),
          withLatestFrom(readerApi$),
          mergeMap(([annotation, api]) => {
            const { resourceUUID } = annotation;

            return this.annotationsService
              .deleteAnnotation(api, resourceUUID)
              .pipe(this.postDeleteAnnotaionHandler(annotation));
          }),
        );

        return forkJoin([deletereqs$]);
      }),
      logCatchError('deletePageAnnotations$'),
    );
  });

  private readonly _deleteAllAnnotations$ = this.effect(() => {
    const { readerApi$, requestContext$ } = this.mediatorUtils;
    const { clearAllAnnotations } = annotationsActions;

    const alertType = 'alert-error';
    const translateKey = 'annotation.batch_delete.delete_error';

    return this.readerActions$.pipe(
      ofType(toolbarActions.deleteAllAnnotations),
      withLatestFrom(readerApi$, requestContext$),
      exhaustMap(([_, api, context]) => {
        return this.annotationsService.deleteAnnotations(api, context).pipe(
          tap(() => this.readerStore.removeAlert(translateKey)),
          tap(() => this.store.dispatch(clearAllAnnotations())),
          this.mediatorUtils.catchErrorAlert(
            { alertType, translateKey },
            { passthrough: true },
          ),
          logCatchError('could not delete all annotations', false),
        );
      }),
      logCatchError('_deleteAllAnnotations$'),
    );
  });

  private batchDeleteConfirmationDialog(isAll = false) {
    const messageKey = isAll
      ? 'annotation.batch_delete.message_all'
      : 'annotation.batch_delete.message_pg';

    const title = this.translate.instant('annotation.batch_delete.title');
    const content = this.translate.instant(messageKey);
    const closeText = this.translate.instant('shared.cancel');
    const confirmText = this.translate.instant('shared.ok');

    const data: ConfirmationModalData = {
      title,
      content,
      closeText,
      confirmText,
    };

    return this.dialog.open(ConfirmationModalComponent, {
      data,
      ariaLabelledBy: 'confirmation-modal-h2',
    });
  }

  private readonly postDeleteAnnotaionHandler =
    (annotation: ApiAnnotation) =>
      <T>(source$: Observable<T>) => {
        const { removeAnnotation } = annotationsActions;

        const alertType = 'alert-error';
        const translateKey = 'annotation.batch_delete.delete_error';
        const alert: ReaderAlert = { alertType, translateKey };

        return source$.pipe(
          tap(() => this.store.dispatch(removeAnnotation({ annotation }))),
          map((source) => source),
          this.mediatorUtils.catchErrorAlert(alert, { passthrough: true }),
          logCatchError('could not delete annotation', false),
        );
      };
}

/** maping helpers */
function fromApiAnnotationToAnnotationData(
  annotation: ApiAnnotation,
): AnnotationData | undefined {
  const shape = annotation?.highlight ? annotation.shape : undefined;
  const color = annotation?.color ?? 'yellow-highlight';

  if (!annotation) {
    return undefined;
  }
  return {
    id: annotation.resourceUUID,
    cfi: annotation.cfi,
    text: annotation.text,
    note: annotation.note,
    placemarkText: annotation.placemarkText,
    color,
    shape,
    highlight: annotation.highlight,
    clickable: true,
  };
}

function mapAnnotationUpsert(
  annotation: Partial<ApiAnnotation>,
): ApiAnnotation {
  const now = nowString();
  let { createdBy, clientCreatedAt } = annotation;

  createdBy = createdBy || 'W';
  clientCreatedAt = clientCreatedAt || now;

  // https://jira.mheducation.com/browse/EPR-7340?focusedCommentId=2919305&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-2919305
  // In short, it is expected for all annotations to have a color even when there's no highlight.  We'll default to yellow
  const color = annotation?.color ?? 'yellow-highlight';
  const shape = annotation?.shape ?? 'epr-shape-square-filled';

  const fullAnnotation: ApiAnnotation = {
    ...(annotation as ApiAnnotation),
    createdBy,
    clientCreatedAt,
    modifiedBy: 'W',
    clientModifiedAt: now,
    color,
    shape,
  } satisfies ApiAnnotation;

  return fullAnnotation;
}

function nowString() {
  const d = new Date();
  return (
    format(addMinutes(d, d.getTimezoneOffset()), 'YYYY-MM-DD[T]HH:mm:ss.SSS') +
    'Z'
  );
}

function isInsideLink({ tagName, parentElement }: HTMLElement) {
  if (tagName && tagName !== 'BODY') {
    if (parentElement?.tagName === 'A') {
      return true;
    }
    return isInsideLink(parentElement as HTMLElement);
  }

  return false;
}
