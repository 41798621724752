import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[rdrxMatTabGroupDirective]',
})
export class MatTabGroupDirective {
  @Output() matTabGroupBlur = new EventEmitter<string>();

  @HostListener('focusout', ['$event']) onfocusout(): void {
    this.matTabGroupBlur.emit();
  }
}
