import {
  Directive,
  HostListener,
  Optional,
  Host,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { TooltipDirective } from '@mhe/ngx-shared/tooltip';

@Directive({
  selector: '[rdrxHideOnEscape]',
  exportAs: 'rdrxHideOnEscape',
})
export class HideOnEscapeDirective {
  // support multiple mheTooltips on children, such as in a tab group
  @ContentChildren(TooltipDirective, { descendants: true }) tooltips!: QueryList<TooltipDirective>;

  // only for the escape key
  @HostListener('keydown.Escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent): void {
    // might be a directive adjacent to the mheTooltip
    if (this.tooltip && this.tooltip.isOpen()) {
      this.closeOnlyTheTooltip(this.tooltip, event);
      return;
    }

    // might be a directive on a parent where one or more children have mheTooltip
    this.tooltips.forEach((tooltip) => {
      if (tooltip.isOpen()) {
        this.closeOnlyTheTooltip(tooltip, event);
      }
    });
  }

  constructor(
    // support a single mheTooltip adjacent to this directive
    @Optional() @Host() private readonly tooltip: TooltipDirective,
  ) { }

  private closeOnlyTheTooltip(tooltip, event): void {
    // close the tooltip
    tooltip.close();

    // do not close the drawer
    event.stopPropagation();
  }
}
