<div class="header go-to-active" (click)="focusActiveNode($event)" (keydown.Enter)="focusActiveNode($event)"
  (keydown.Space)="focusActiveNode($event)">
  <div style="display: flex;">
    <h3 style="padding: 0 8px;" translate>contents.toc 
    </h3>
    <div style="position: relative;">
      <i
        tabindex="0"
        role="button"
        class="infobtn dpg-icon dpg-icon-system-alert-info"
        (click)="handleInfoClick($event)"
        (keydown.escape)="closeInfo($event)"
        (keydown.enter)="handleInfoClick($event)">
      </i>
      <div class="info"
        [ngClass]="{'visible': isInfoVisible }"
        [innerHTML]="'contents.toc_info' | translate"
        [attr.aria-expanded]="isInfoVisible">
      </div>  
    </div>
  </div>

  <a href="javascript:void(0)" data-automation-id="go-to-current-location" class="ahe-ui-link">
    {{ 'contents.toc_go_current_location' | translate }}
  </a>

</div>

<ngx-bonsai
  class="rdrx"
  rdrxBonsaiTabIndex
  data-automation-id="toc-tree-group"
  [nodesById]="tree$ | async"
  [rootNodeId]="rootNodeId"
  [expandedNodes]="expandedNodes$ | async"
  [showToggleFn]="isCollapsible"
  (expanderClick)="toggleNodeExpanded($event)"
>
  <ng-template #treeNodeTemplate let-node>
    <a
      attr.aria-haspopup
      mheStopPropagation
      rdrxBonsaiNodeSelect
      href="javascript:void(0)"
      class="rdrx-node-content ahe-ui-link"
      data-automation-id="toc-tree-children"
      tabindex="-1"
      [rdrxTocActiveNode]="node.id === (activeId$ | async)"
      [rdrxBonsaiNodeSelectKeyboard]="!node.collapsible"
      (click)="handleNodeClick(node)"
    >
      {{ node.title }}
    </a>
  </ng-template>
</ngx-bonsai>
