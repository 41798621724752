<div
  role="region"
  [attr.aria-label]="'readspeaker.read_aloud' | translate"
  (keydown.escape)="onClose()"
  class="audio-controls"
>
  <div #controlRow class="control-row">
    <ng-container *ngIf="(hasError$ | async) === false; else audioErrorMessage">
      <button
        #playPauseButton
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="tts-button mhe-a11y"
        data-automation-id="play-pause-toggle-button"
        (click)="onTogglePlay()"
        [mheTooltip]="((isPlaying$ | async) ? 'readspeaker.pause' : 'readspeaker.play') | translate"
      >
        <ng-container [ngSwitch]="isPlaying$ | async">
          <i *ngSwitchCase="true" class="dpg-icon dpg-icon-av-control-pause-outline"></i>
          <i *ngSwitchCase="false" class="dpg-icon dpg-icon-av-control-play-outline"></i>
        </ng-container>
      </button>
      <mat-slider
        #ngSlider
        *ngIf="showPlaybackScrubber"
        class="mhe-a11y rdrx-slider tts-scrubber playback-scrubber"
        data-automation-id="progress-slider-control"
        >
        <input
            matSliderThumb
            #ngSliderThumb="matSliderThumb"
            [value]="round(100 / (totalPlaybackTime$ | async) * (playbackPosition$ | async))"
            (change)="onSkip({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})"
            (input)="onPlaybackScrubberMove()"
            [mheTooltip]="'readspeaker.progress_control' | translate"
            />
      </mat-slider>
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="tts-button mhe-a11y"
        data-automation-id="mute-unmute-toggle-button"
        (click)="onToggleMute()"
        [mheTooltip]="((isMuted$ | async) ? 'readspeaker.unmute' : 'readspeaker.mute') | translate"
      >
        <ng-container [ngSwitch]="isMuted$ | async">
          <i *ngSwitchCase="true" class="dpg-icon dpg-icon-audiooff"></i>
          <i *ngSwitchCase="false" class="dpg-icon dpg-icon-audioon"></i>
        </ng-container>
      </button>
      <mat-slider
        #ngSlider
        class="mhe-a11y rdrx-slider tts-scrubber volume-scrubber"
        data-automation-id="volume-slider-control"
        [max]="1"
        [min]="0"
        [step]="0.01"
        >
        <input
            matSliderThumb
            [value]="volume$ | async"
            (change)="onVolumeChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})"
            #ngSliderThumb="matSliderThumb"
            [mheTooltip]="'readspeaker.volume_control' | translate"
            [attr.aria-valuetext]="(volume$ | async) * 100"
            [attr.aria-valuenow]="((volume$ | async) || 0) * 100"
          />
      </mat-slider>
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        class="tts-button mhe-a11y speed-control-toggle"
        data-automation-id="speed-control-toggle-button"
        (click)="onViewPlaybackRateControl()"
        [attr.aria-expanded]="showSpeedControlDropDown$ | async"
        [mheTooltip]="'readspeaker.view_speed_control' | translate"
      >
        <i class="dpg-icon dpg-icon-dashboard-gauge"></i>
      </button>

      <div
        *ngIf="showSpeedControlDropDown$ | async"
        class="speed-control-dropdown"
        data-automation-id="speed-slider-control"
        [ngStyle]="{ width: _width }"
      >
        <label class="tts-speed-label">
          {{ 'readspeaker.speed' | translate }}:
          <!-- The 'tickInterval' was change to 'step' -->
          <mat-slider
            #ngSlider
            class="mhe-a11y rdrx-slider tts-scrubber rate-scrubber"
            data-automation-id="speed-slider-control"
            showTickMarks
            [max]="playbackRateValues[playbackRateValues.length - 1]"
            [min]="playbackRateValues[0]"
            [step]="0.25"
            (input)="onPlaybackRateChange($event)"
            placement="bottom"
          >
            <input
              value="1"
              matSliderThumb
              #ngSliderThumb="matSliderThumb"
              [mheTooltip]="'readspeaker.speed_control' | translate"
            />
          </mat-slider>
        </label>
        <div data-automation-id="playback-speed-rate" class="playback-rate-display">
          {{ playbackRate$ | async }}x
        </div>
      </div>
    </ng-container>

    <button
      *ngIf="showClose"
      data-automation-id="readspeaker-close-button"
      (click)="onClose()"
      mhe-button
      [purpose]="ButtonPurpose.Icon"
      [mheTooltip]="'readspeaker.close' | translate"
    >
      <i class="dpg-icon dpg-icon-system-close"></i>
    </button>
  </div>

  <div *ngIf="showSpeedControlDropDown$ | async" class="control-row bottom-row">
    <!-- THIS SPACE IS LEFT INTENTIONALLY BLANK -->
    <!-- This space is for the speed controls to float above. -->
    <!-- So that we can get the tab ordering we want, -->
    <!-- as well as "second row" UX we want too. -->
  </div>
</div>

<ng-template #audioErrorMessage>
  <!-- mhe-alert error types cannot be closed! -->
  <!-- TODO translate this error message here and all places -->
  <mhe-alert class="audio-error-alert" [smallAlert]="true" [type]="AlertType.Error">
    Audio failed. Try again later.
  </mhe-alert>
</ng-template>
