import { Component, OnInit, inject } from '@angular/core';
import { AlertModule, AlertType } from '@mhe/ngx-shared';
import { TranslateModule } from '@ngx-translate/core';
import { MaintenanceService } from '@mhe/reader/components/alerts/maintenance/maintenance.service';

@Component({
  selector: 'reader-ui-maintenance-alert',
  standalone: true,
  imports: [
    AlertModule,
    TranslateModule,
  ],
  providers: [MaintenanceService],
  template: `
    @let maintenanceData = maintenanceService.maintenanceData();
    @if (maintenanceData && !closedByUser) {
      <mhe-alert
        [type]="AlertType.Warning"
        (close)="closeMaintenanceAlert()"
      >
        McGraw Hill will be performing maintenance from
        {{ maintenanceData.from }}. to
        {{ maintenanceData.to }}. ET on
        {{ maintenanceData.date }}.
        The system may be unavailable during this time.
        We apologize for the inconvenience and appreciate your patience as we work
        to provide you the best experience possible.
      </mhe-alert>
    }
  `,
})
export class MaintenanceComponent implements OnInit {
  protected readonly AlertType = AlertType;

  readonly maintenanceService = inject(MaintenanceService);

  closedByUser = false;

  ngOnInit(): void {
    this.maintenanceService.getMaintenanceData();
  }

  closeMaintenanceAlert(): void {
    this.closedByUser = true;
  }
}
