import { Injectable } from '@angular/core';
import {
  TTSAzureRawTextRequest,
  TTSAzureRequest,
  TTSChunk,
  TTSChunkCollection,
  TTSRawTextTranslationResponse,
} from '@mhe/reader/models';
import { Observable } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import * as readerConfigQuery from '@mhe/reader/core/state/configuration/configuration.selectors';
import { IReaderClient } from '../../models/reader.client.model';

@Injectable({ providedIn: 'root' })
export class ReaderApiClient implements IReaderClient {
  private readonly baseUrl$: Observable<string | undefined>;

  constructor(store: Store, private readonly http: HttpClient) {
    this.baseUrl$ = store.pipe(
      select(readerConfigQuery.getReaderApi),
      filter((s: string | undefined) => typeof s === 'string' && s.length > 0),
      first(),
    );
  }

  /**
   * Obtains status on an existing text-to-speech job. The resonse contains
   * multiple ordered chunks
   */
  fetchTextToSpeechJob(
    requestCacheKey: string,
  ): Observable<TTSChunkCollection> {
    return this.baseUrl$.pipe(
      mergeMap((readerApiBaseUrl) => {
        const url = `${readerApiBaseUrl}/v1/tts/chunk/status?requestCacheKey=${requestCacheKey}`;
        return this.http.post<TTSChunkCollection>(url, {});
      }),
    );
  }

  /**
   * Sends content to be translated for text-to-speech. In
   * the response, the first chunk mp3 url is provided and
   * the metadata.requestCacheKey is used to poll an endpoint
   * for completion of the remaining chunks.
   */
  createTextToSpeechJob(params: TTSAzureRequest): Observable<TTSChunk | null> {
    return this.baseUrl$.pipe(
      mergeMap((readerApiBaseUrl) => {
        const url = `${readerApiBaseUrl}/v1/tts/chunk`;
        const urlencoded = new URLSearchParams();
        Object.keys(params).forEach(key => urlencoded.append(key, params[key]));
        return this.http.post<TTSChunk>(url, urlencoded, {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }), // skip the token interceptor
        });
      }),
    );
  }

  translateRawTextToSpeech(
    params: TTSAzureRawTextRequest,
  ): Observable<TTSRawTextTranslationResponse> {
    return this.baseUrl$.pipe(
      mergeMap((readerApiBaseUrl) => {
        const url = `${readerApiBaseUrl}/v1/tts`;
        const urlencoded = new URLSearchParams();
        Object.keys(params).forEach(key => urlencoded.append(key, params[key]));
        return this.http.post<TTSRawTextTranslationResponse>(url, urlencoded, {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });
      }),
    );
  }
}
