import { ExtendedComponentStore } from '@mhe/reader/common';
import { CurrentView, EPubAudio, initialState, TTSState } from './tts.state';
import * as actions from './tts.actions';
import {
  Utterance,
  AudioChunkCollection,
  ChunkedAudioProgress,
  TTSPlaybackMode,
  AudioChunk,
  TTSJobStatus,
  TTSAudioContext,
  TTSVoice,
  TTSVoiceProviders,
} from '@mhe/reader/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TTSStore extends ExtendedComponentStore<
TTSState,
actions.TTSActions
> {
  constructor() {
    super(initialState);
  }

  readonly fullState$ = this.select((state) => state);

  readonly docTTSControlReq$ = this.select((state) => state.docTTSControlReq);
  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly currentView$ = this.select((state) => state.currentView);
  readonly playbackMode$ = this.select((state) => state.playbackMode);
  readonly voice$ = this.select((state) => state.voice);
  readonly voiceName$ = this.select((state) => state.voice?.name);
  readonly voiceProvider$ = this.select((state) => state.voiceProvider);

  readonly audioSetting$ = this.select((state) => state.audioSettings);
  readonly volume$ = this.select(
    this.audioSetting$,
    (settings) => settings.volume,
  );

  readonly playbackRate$ = this.select(
    this.audioSetting$,
    (settings) => settings.playbackRate,
  );

  readonly disabled$ = this.select(({ disabled }) => disabled);

  /**
   * A set of audio keys used to construct the resulting activeAudio.
   */
  readonly activeAudioKey$ = this.select((state) => state.activeAudioKeys);

  /**
   * Returns the active audio entries.
   */
  readonly activeAudioItem$ = this.select(
    this.activeAudioKey$,
    this.state$,
    (activeAudioKeys, state) => {
      return activeAudioKeys
        ? activeAudioKeys.map((key) => state.audioDictionary[key])
        : [];
    },
  );

  /**
   * Concatenates the EPubAudio based on the active keys.  Multiple keys
   * are used solely for double spread ePubs.
   * NOTE: Due to the construction, this will return a new object on each
   * state update, which can get noisy.
   */
  readonly activeAudio$ = this.select(
    this.activeAudioItem$,
    (activeAudioItems): EPubAudio | undefined => {
      const itemsContainAudioChunks = activeAudioItems.some(
        (item) => !!item?.chunkCollection?.chunks,
      );
      if (!itemsContainAudioChunks) {
        // undefined means the audio has not been fetched yet.
        // This is different from defined, but an empty chunk
        // collection (bad fetch).
        return undefined;
      } else {
        const result: EPubAudio = {
          utterances: [],
          chunkCollection: {
            chunks: [],
            status: undefined,
            cfiPath: undefined,
            voice: undefined,
          },
        };

        // Loop through the items and build the audio based
        // on the cached audio chunks.
        activeAudioItems.forEach((cachedAudioData, i) => {
          if (cachedAudioData?.utterances) {
            result.utterances = [
              ...result.utterances,
              ...cachedAudioData.utterances,
            ];
          }

          // Below we build out the active audio chunkCollection.
          // We only extend the active audio with contiguous chunks.
          // If we allow gaps, then resuming playback could find
          // the wrong chunk due to the timing calcuation below.
          const chunks = cachedAudioData?.chunkCollection?.chunks;
          if (chunks) {
            // To avoid gaps in the aggregate chunk sequence, ensure
            // any previous collection is complete before adding on this
            // current audio collection.
            const prevCollectionComplete =
              i === 0
                ? true
                : activeAudioItems[i - 1].chunkCollection?.status ===
                  TTSJobStatus.COMPLETE;
            if (prevCollectionComplete) {
              // The playableChunks are the contiguous chunks starting from i=0.
              let playableChunks: AudioChunk[];

              const currCollectionComplete =
                cachedAudioData.chunkCollection?.status ===
                TTSJobStatus.COMPLETE;
              if (currCollectionComplete) {
                // Add all the chunks, no gaps in the sequence to worry about.
                playableChunks = chunks;
              } else {
                // This audio collection is not complete, so the
                // chunks could contain gaps in the sequence, e.g.
                // the app could have received chunks 1 and 3.
                // If we allow [1, 3] to be active audio, then resuming
                // playback could play the wrong chunk due to the aggregate
                // timing caculation.
                // Below, we determine the playable subset of chunks.

                // Note this search below assumes the chunks are sorted,
                // which should be true based on the parsing in the mediator.
                const lastContiguousChunkIndex = chunks.findIndex(
                  (chunk, j) => {
                    if (j === chunks.length - 1) {
                      // at the end, all clear
                      return true;
                    } else {
                      // we have a next chunk to interrogate
                      const nextChunk = chunks[j + 1];
                      const isSequential = chunk.index + 1 === nextChunk.index;
                      return !isSequential;
                    }
                  },
                );
                playableChunks = chunks.slice(0, lastContiguousChunkIndex + 1);
              }
              // Only add playableChunks to the active audio.
              result.chunkCollection.chunks = [
                ...result.chunkCollection.chunks,
                ...playableChunks,
              ];
              // Refect the status of the last collection processed. This
              // should work because the collections are added to the aggregate
              // only if the prevous collection was complete. So if the last
              // collection is complete, then the aggregate is complete, and
              // vice versa.
              result.chunkCollection.status =
                cachedAudioData.chunkCollection?.status;

              // Add voice so mediator can check if the new audio has a different voice from the previous one
              result.chunkCollection.voice =
                cachedAudioData.chunkCollection?.voice;
            }
          }
        });

        // Calculate the timing, update indices for this combination of audio.
        let startTime = 0;
        result.chunkCollection.chunks = result.chunkCollection.chunks.map(
          (chunk, i) => {
            const chunkDuration = chunk.timing[chunk.timing.length - 1].max;
            const endTime = startTime + chunkDuration;
            const parsedChunk = { ...chunk, startTime, endTime, index: i };
            startTime = endTime;
            return parsedChunk;
          },
        );

        return result;
      }
    },
  );

  readonly activeAudioTotalPlaybackTime$: Observable<number | undefined> =
    this.select(
      this.activeAudio$,
      (activeAudio) =>
        activeAudio?.chunkCollection?.chunks[
          activeAudio.chunkCollection.chunks.length - 1
        ]?.endTime,
    );

  readonly activeAudioPlaybackProgress$: Observable<
  ChunkedAudioProgress | undefined
  > = this.select((state) => state.currentProgress);

  readonly setVolume = this.updater((state, volume: number) => {
    return {
      ...state,
      audioSettings: {
        ...state.audioSettings,
        volume,
      },
    };
  });

  readonly setPlaybackRate = this.updater((state, playbackRate: number) => {
    return {
      ...state,
      audioSettings: {
        ...state.audioSettings,
        playbackRate,
      },
    };
  });

  readonly setDisabled = this.updater((state, disabled: boolean) => {
    return {
      ...state,
      disabled,
    };
  });

  readonly setCurrentView = this.updater((state, currentView: CurrentView) => {
    return {
      ...state,
      currentView,
    };
  });

  readonly setVoice = this.updater((state, voice: TTSVoice) => {
    return { ...state, voice };
  });

  readonly setVoiceProvider = this.updater((state, voiceProvider: TTSVoiceProviders) => {
    return { ...state, voiceProvider };
  });

  readonly setActiveAudio = this.updater(
    (
      state,
      {
        keys,
        playbackMode,
      }: {
        keys: string[] | undefined
        playbackMode: TTSPlaybackMode | undefined
      },
    ) => {
      return {
        ...state,
        activeAudioKeys: keys,
        playbackMode,
      };
    },
  );

  readonly setIsLoading = this.updater((state, isLoading: boolean) => {
    return {
      ...state,
      isLoading,
    };
  });

  readonly setAudioChunks = this.updater(
    (
      state,
      {
        key,
        chunkCollection,
      }: { key: string, chunkCollection: AudioChunkCollection | undefined },
    ) => {
      let newChunkCollection: AudioChunkCollection | undefined;
      const existingChunkCollection =
        state.audioDictionary[key]?.chunkCollection;
      const existingChunks: AudioChunk[] =
        existingChunkCollection?.chunks ?? [];
      if (!chunkCollection) {
        // clear the cache
        newChunkCollection = undefined;
      } else if (chunkCollection.voice !== existingChunkCollection?.voice) {
        // Only if voice LTI flag is on?
        // Override existing chunks with chunks containing different voice.
        // Investigate caching by cfi, index and voice another time
        newChunkCollection = { ...chunkCollection };
      } else {
        // Upsert the cache. Preserve all
        // audio objects set in the existing cache.
        const newChunks = chunkCollection.chunks.map((chunk) => {
          const existingChunk = existingChunks.find(
            (c) => c.index === chunk.index,
          );
          return { ...chunk, audio: existingChunk?.audio };
        });
        newChunkCollection = { ...chunkCollection, chunks: newChunks };
      }

      return {
        ...state,
        audioDictionary: {
          ...state.audioDictionary,
          [key]: {
            ...state.audioDictionary[key],
            chunkCollection: newChunkCollection,
          },
        },
      };
    },
  );

  readonly setAudioUtterances = this.updater(
    (state, { key, utterances }: { key: string, utterances: Utterance[] }) => {
      return {
        ...state,
        audioDictionary: {
          ...state.audioDictionary,
          [key]: { ...state.audioDictionary[key], utterances },
        },
      };
    },
  );

  readonly setAudioProgress = this.updater(
    (state, currentProgress: ChunkedAudioProgress): TTSState => {
      const context = currentProgress.metadata?.activeChunk.context;
      if (context === TTSAudioContext.PAGE) {
        // Here we additionally store the page currentProgress
        // on the current view so we can resume page
        // playback after other audio is played.
        return {
          ...state,
          currentView: {
            ...state.currentView,
            progress: currentProgress,
          },
          currentProgress,
        };
      } else {
        return {
          ...state,
          currentProgress,
        };
      }
    },
  );

  readonly setShowDocTTSControls = this.updater(
    (state, docTTSControlReq: boolean) => {
      return {
        ...state,
        docTTSControlReq,
      };
    },
  );

  /**
   * Cache the HTML audio in the store to prevent http requests.
   * We shouldn't depend on browser caching to manage the mp3
   * files because we don't own the server that hosts them.
   */
  readonly cacheAudio = this.updater((state: TTSState, chunk: AudioChunk) => {
    const oldChunks =
      state.audioDictionary[chunk.cfiPath].chunkCollection?.chunks ?? [];
    const chunkIndex = oldChunks?.findIndex((c) => c.index === chunk.index);
    const updatedChunk = { ...oldChunks[chunkIndex], audio: chunk.audio };

    // copy array to keep things immutable
    const updatedChunks = [...oldChunks];
    // replace our chunk
    updatedChunks[chunkIndex] = updatedChunk;

    return {
      ...state,
      audioDictionary: {
        ...state.audioDictionary,
        [chunk.cfiPath]: {
          ...state.audioDictionary[chunk.cfiPath],
          chunkCollection: {
            ...(state.audioDictionary[chunk.cfiPath]
              .chunkCollection as AudioChunkCollection),
            chunks: updatedChunks,
          },
        },
      },
    } satisfies TTSState;
  });
}
