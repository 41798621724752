/* eslint-disable max-len */
import { A11yModule } from '@angular/cdk/a11y';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ButtonModule, TextAreaModule, CheckboxModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AnnotationsContextMenuComponent } from './annotations-context-menu.component';
import { ColorIconComponent } from './color-icon/color-icon.component';
import { TTSModule } from '@mhe/reader/components/text-to-speech';
import { QuickAnnotationComponent } from '@mhe/reader/components/annotations-context-menu/quick-annotation/quick-annotation.component';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';

@NgModule({
  imports: [
    CommonModule,
    CommonDirectivesModule,
    ButtonModule,
    TooltipModule,
    CheckboxModule,
    TranslateModule,
    A11yModule,
    TextAreaModule,
    TTSModule,
  ],
  declarations: [
    AnnotationsContextMenuComponent,
    ColorIconComponent,
    QuickAnnotationComponent,
  ],
  exports: [AnnotationsContextMenuComponent],
  providers: [GoogleAnalyticsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnnotationsContextMenuModule {}
