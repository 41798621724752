import { Component, Input } from '@angular/core';
import { HighlightColor } from '@mhe/reader/components/annotations-context-menu';
import { HighlightColorClass, HighlightShapeClass, ShapeDescription } from '@mhe/reader/models';

@Component({
  selector: 'rdrx-highlight-icon',
  templateUrl: './highlight-icon.component.html',
  styleUrls: ['./highlight-icon.component.scss'],
})
export class HighlightIconComponent {
  @Input() color: HighlightColorClass;
  @Input() shape: HighlightShapeClass;

  get title(): string {
    return `${getColorDescription(this.color)} ${getShapeDescription(this.shape)} highlight`;
  }

  get border(): string {
    return this.colors[this.color]?.border;
  }

  get fill(): string {
    return this.colors[this.color]?.fill;
  }

  colors = {
    'yellow-highlight': {
      fill: '#FFF19A',
      border: '#C5871B',
    },
    'green-highlight': {
      fill: '#CFFB54',
      border: '#729F41',
    },
    'blue-highlight': {
      fill: '#BDD7FC',
      border: '#109BE0',
    },
    'pink-highlight': {
      fill: '#FFBEDC',
      border: '#FF52A3',
    },
  };
}

function getColorDescription(colorClass: HighlightColorClass): HighlightColor {
  switch (colorClass) {
    case 'blue-highlight':
      return 'blue';
    case 'green-highlight':
      return 'green';
    case 'pink-highlight':
      return 'pink';
    case 'yellow-highlight':
      return 'yellow';
    case 'none':
      return 'none';
  }
}

function getShapeDescription(shapeClass: HighlightShapeClass): ShapeDescription {
  switch (shapeClass) {
    case 'epr-shape-round-outline':
      return 'round outline';
    case 'epr-shape-square-filled':
      return 'square filled';
    case 'epr-shape-square-outline':
      return 'square outline';
    case 'epr-shape-underline':
      return 'underline';
    case 'none':
      return 'none';
  }
}
