/* eslint-disable @angular-eslint/no-output-native */
import {
  ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild,
} from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { map } from 'rxjs/operators';

import { ReaderConfigStore } from '../reader/state';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';

@Component({
  selector: 'rdrx-contents-menu',
  templateUrl: './contents-menu.component.html',
  styleUrls: ['./contents-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentsMenuComponent {
  @Output() closeEvent = new EventEmitter<void>();
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  selectedTabIndex: number = 0;

  readonly single$ = this.readerConfigStore.contentsMenuConfig$.pipe(
    map((config) => Object.keys(config).filter((key) => config[key] === true)),
    map((contents) => contents.length === 1),
  );

  readonly config$ = this.readerConfigStore.contentsMenuConfig$;
  readonly k5$ = this.readerConfigStore.k5$;

  // expose import ref
  readonly ButtonPurpose = ButtonPurpose;

  constructor(
    private readonly ga: GoogleAnalyticsService,
    private readonly readerConfigStore: ReaderConfigStore,
  ) {}

  setFocus(): void {
    this.tabGroup.focusTab(this.selectedTabIndex);
  }

  onFocusChange(event: string): void {
    this.updateTabIndexes();
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    const eventAction = event.tab.textLabel;
    this.ga.event({ eventCategory: 'Contents Menu', eventAction });
    this.updateTabIndexes();
  }

  updateTabIndexes(): void {
    const tabs = Array.from(document.querySelectorAll('div[role=tab]'));
    tabs.forEach((element: HTMLDivElement, index: number) => {
      element.tabIndex = index === this.selectedTabIndex ? 0 : -1;
    });
  }
}
