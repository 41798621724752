import { Injectable, Signal, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface IMaintenanceData {
  from: string
  to: string
  date: string
}

@Injectable()
export class MaintenanceService {
  readonly #maintenanceData = signal<IMaintenanceData | null>(null);
  constructor(
    private readonly http: HttpClient,
  ) { }

  getMaintenanceData(): void {
    this.http
      .get<IMaintenanceData>('/assets/maintenance-data.json')
      .subscribe(
        (jsonData) => this.#maintenanceData.set(jsonData),
      );
  }

  get maintenanceData(): Signal<IMaintenanceData | null> {
    return this.#maintenanceData.asReadonly();
  }
}
