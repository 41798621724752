import {
  AllowedAssignment,
  AllowedContentWidth,
  // TODO duplicated type
  AllowedInterfaceMode,
  AllowedLevel,
  // TODO duplicated type
  AllowedNavigationType,
  AllowedViewMode,
} from '@mhe/reader/types';
import { LexileLevel } from './epub';
import { TTSGender } from './tts.model';

export interface RuntimeConfiguration {
  readonly assessmentLtiPost: string | undefined
  readonly assessmentLtiPostLaunchParams: string | undefined
  readonly assignment: AllowedAssignment
  readonly assignmentXid: string | undefined
  readonly assnId: string | undefined
  readonly authoring: boolean
  readonly backButton: boolean
  readonly cfi: string | undefined
  readonly clId: string | undefined
  readonly contentType: false | string
  readonly contentWidth: AllowedContentWidth
  readonly contextId: string
  readonly contextLabel: false | string
  readonly contextMenu2: boolean
  readonly customPartnerId: string
  readonly deliveryScoresheetView: string | undefined
  readonly digitalSample: boolean
  readonly displayLevels: undefined | AllowedLevel[]
  readonly dfaCategoryUUID: string | undefined
  readonly dfaTopicUUID: string | undefined
  readonly dfaSearchLaunchSpineID: string | undefined
  readonly dfaSearchLaunchIndex: number | undefined
  readonly dfaSearchLaunchText: string | undefined
  readonly fontResizer: boolean
  readonly highlights: boolean
  readonly interfaceMode: AllowedInterfaceMode | undefined
  readonly lastLocation: boolean
  readonly lastReopen: number | undefined
  readonly launchPresentationCssUrl: string | undefined
  readonly launchPresentationReturnUrl: string | undefined
  readonly launchTimestamp: string
  readonly levelDefault: AllowedLevel | undefined
  readonly lexileLevelCourse: LexileLevel | undefined
  readonly lexileLevelUser: LexileLevel | undefined
  readonly links: boolean
  readonly lisOutcomeServiceUrl: string | undefined
  readonly lisResultSourcedId: string | undefined
  readonly locale: string | undefined
  readonly location: boolean
  readonly navbar: boolean
  readonly navbarFixed: boolean
  readonly navigation: boolean
  readonly navigationType: AllowedNavigationType
  readonly notes: boolean
  readonly overflow: boolean
  readonly pageStartCfi: string | undefined
  readonly pageEndCfi: string | undefined
  readonly placemarks: boolean
  readonly platform: string
  readonly previewMode: boolean
  readonly print: boolean
  readonly rangeStart: string | undefined
  readonly rangeEnd: string | undefined
  readonly readiator: boolean
  readonly readspeaker: boolean
  readonly readspeakerGender: TTSGender | undefined
  readonly readspeakerVoicePick: boolean
  readonly resourceLinkId: string | undefined
  readonly resourceLinkTitle: string | undefined
  readonly roles: string | undefined
  readonly scrubber: boolean
  readonly search: boolean
  readonly sectionXid: string | undefined
  readonly sessionDisabled: boolean
  readonly singlePageViewOnly: boolean
  readonly tagging: boolean
  readonly taggingElements: string | undefined
  readonly titleOverride: string | undefined
  readonly toc: boolean
  readonly token: string
  readonly ttsHighlighting: boolean
  readonly userId: string
  readonly viewMode: AllowedViewMode
  readonly widgetExpand: boolean
  readonly oauthConsumerKey: string
  readonly elasticSearchEndpoint: string
  readonly elasticSearchSuggestionsEndPoint: string
  readonly elasticSearchMinChars: number
  readonly topicsEndpoint: string
  readonly subjectTopicsEndpoint: string
  readonly clientPendoApiKey: string
  readonly readerPendoApiKey: string
  readonly pendoClientList: string[]
  readonly orgXid: string
  readonly readerHeightInSeamless: number
  readonly idmToken: string | null
  readonly darkVersion: boolean
  readonly epubReleaseUUID: string | undefined
  readonly epubVersion: string | undefined
  readonly fallbackToDefaultPublish: boolean
  readonly isAiAssistEnabled: boolean
  readonly personXid: string
  readonly aiAssistAudience: string
}

export const defaultRuntimeConfiguration: RuntimeConfiguration = {
  assessmentLtiPost: undefined,
  assessmentLtiPostLaunchParams: undefined,
  assignment: '',
  assignmentXid: undefined,
  assnId: undefined,
  authoring: false,
  backButton: true,
  cfi: undefined,
  clId: undefined,
  contentType: false,
  contentWidth: 'default',
  contextId: '',
  contextLabel: false,
  contextMenu2: false,
  customPartnerId: '',
  deliveryScoresheetView: undefined,
  digitalSample: false,
  displayLevels: undefined,
  dfaCategoryUUID: undefined,
  dfaTopicUUID: undefined,
  dfaSearchLaunchSpineID: undefined,
  dfaSearchLaunchIndex: undefined,
  dfaSearchLaunchText: undefined,
  fontResizer: true,
  highlights: true,
  interfaceMode: 'default',
  lastLocation: true,
  lastReopen: undefined,
  launchPresentationCssUrl: undefined,
  launchPresentationReturnUrl: undefined,
  launchTimestamp: Date.now().toString(),
  levelDefault: undefined,
  lexileLevelCourse: undefined,
  lexileLevelUser: undefined,
  links: true,
  lisOutcomeServiceUrl: undefined,
  lisResultSourcedId: undefined,
  locale: undefined,
  location: true,
  navbar: true,
  navbarFixed: false,
  navigation: true,
  navigationType: 'paginate',
  notes: true,
  overflow: true,
  pageStartCfi: undefined,
  pageEndCfi: undefined,
  placemarks: true,
  platform: 'W',
  previewMode: false,
  print: false,
  rangeStart: undefined,
  rangeEnd: undefined,
  readiator: false,
  readspeaker: true,
  readspeakerGender: undefined,
  readspeakerVoicePick: false,
  resourceLinkId: undefined,
  resourceLinkTitle: undefined,
  roles: undefined,
  scrubber: false,
  search: true,
  sectionXid: undefined,
  sessionDisabled: false,
  singlePageViewOnly: false,
  tagging: false,
  taggingElements: undefined,
  titleOverride: undefined,
  toc: true,
  token: '',
  ttsHighlighting: false,
  userId: '',
  viewMode: 'digital',
  widgetExpand: false,
  oauthConsumerKey: '',
  elasticSearchEndpoint: '',
  elasticSearchSuggestionsEndPoint: '',
  elasticSearchMinChars: 3,
  topicsEndpoint: '',
  subjectTopicsEndpoint: '',
  orgXid: '',
  clientPendoApiKey: '',
  readerPendoApiKey: '',
  pendoClientList: [],
  readerHeightInSeamless: 600,
  idmToken: null,
  darkVersion: false,
  epubReleaseUUID: undefined,
  epubVersion: undefined,
  fallbackToDefaultPublish: false,
  isAiAssistEnabled: false,
  personXid: '',
  aiAssistAudience: 'all',
};
