import { TTSGender, TTSVoice, TTSVoiceName } from './tts.model';

export class PlayerApiClientError extends Error {
  constructor(readonly method: string, readonly path: string, message: string) {
    super(
      `Error while communicating with Player API (${method} ${path}): ${message}`,
    );
  }
}

export type LtiParams = Record<string, string>;

export function isLtiParams(subject: any): subject is LtiParams {
  return (
    typeof subject === 'object' &&
    Object.keys(subject).every(
      (key) => typeof key === 'string' && typeof subject[key] === 'string',
    )
  );
}

export enum TTSMathFlag {
  TRUE = 't',
  FALSE = 'f',
}

export enum TTSJobStatus {
  PENDING = 'pending',
  NOT_FOUND = 'not-found',
  COMPLETE = 'complete',
  STALLED = 'stalled', // determined by UI
}

export enum TTSRawTextType {
  MP3 = 'mp3',
}

export interface TTSRequest {
  content: string
  language: string
  gender: TTSGender
  voice?: TTSVoice
  mathFlag?: TTSMathFlag
}

export interface TTSUtteranceTiming {
  utterances: number[]
  dur: number
  min: number
  max: number
}

export interface ChunkUtteranceBound {
  chunkIndex: number
  max: number
  min: number
}

export interface TTSChunkMetadata {
  chunkCount: 1
  chunkUtteranceBounds: ChunkUtteranceBound[]
  maxUtterances: number
  requestCacheKey: string
  requestGender: TTSGender
  requestLang: string
  requestMathFlag: TTSMathFlag
}

export interface TTSChunk {
  /**
   * The position for this chunk within
   * the text.
   */
  chunkIndex: number

  /**
   * Additional data about the data and request.
   * This contains the requestCacheKey used for
   * fetching the chunk collection.
   */
  metadata: TTSChunkMetadata

  /**
   * A url to obtain the audio file for this
   * chunk.
   */
  mp3: string

  /**
   * Timing data used to align the audio with the
   * written text.
   */
  timing: TTSUtteranceTiming[]
}

export interface TTSChunkCollection {
  /**
   * The total number of chunks in the collection.
   */
  chunkCount: number

  /**
   * An array of chunks within the collection. An element
   * that is undefined means that the chunk has not completed
   * processing.
   */
  chunks: Array<TTSChunk | undefined>

  /**
   * How many chunks have been processed out of
   * the total chunkCount.
   */
  processedCount: number

  /**
   * An indication of whether the collection
   * has completed processing.
   */
  status: TTSJobStatus

  /**
   * Additional data about the data and request.
   */
  meta: TTSChunkMetadata
}

export interface TTSRawTextTranslationResponse {
  /**
   * The audio file URL.
   */
  location?: string
}

export interface TTSRawTextRequest {
  // Readspeaker accepts plain text and HTML strings on this param
  // this is better than the "text" param which would not read HTML entites
  html: string

  language: string
  gender: TTSGender
  voice?: TTSVoiceName
  type: TTSRawTextType
}

export interface EBookshelfConcurrencyResponse {
  result: string | number // API sends multiple data types:  '0' or 1
}

export interface LogoutResponse {
  status: string
}
