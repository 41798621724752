<ng-container *ngIf="showVoiceOptions">
  <div class="voice-selection">
    <div class="voices-provider-list">
      <h4 class="header">Choose Voice Sample Provider</h4>
      <ul
        class="voice-provider-selection"
        [ngClass]="(docTTSControlReq$ | async) ? 'audio-controls-open' : 'audio-controls-closed'"
      >
        <li
          *ngFor="let voiceProvider of ttsVoiceProvider"
          class="voice-provider-list-items"
          [ngClass]="{
              'voice-selection-opt': (isLoading$ | async) === false,
              'voice-selection-opt-selected': voiceProvider.provider === ttsProviderSelected,
              'voice-selection-disabled': (isLoading$ | async)
            }"
          (click)="onReadProviderVoicePickClick(voiceProvider)"
        >
          {{ voiceProvider.name }}
        </li>
      </ul>
    </div>
    <ng-container
      *ngIf="ttsProviderSelected === TTSVoiceProviders.AZURE; else readspeakerVoices"
    >
      <div class="azure-voices-list">
        <h4 class="header">Azure Voice Sampler</h4>
        <ul
          class="azure-voice-selection"
          [ngClass]="(docTTSControlReq$ | async) ? 'audio-controls-open' : 'audio-controls-closed'"
        >
          <li
            *ngFor="let voice of azureTtsVoices"
            [ngClass]="{
              'voice-selection-opt': (isLoading$ | async) === false,
              'voice-selection-opt-selected': voice.name === (selectedVoice$ | async),
              'voice-selection-disabled': (isLoading$ | async)
            }"
            (click)="onReadVoicePickClick(voice)"
          >
            {{ voice.display }}
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-template #readspeakerVoices>
      <div class="readspeaker-voices-list">
        <h4 class="header">Readspeaker Voice Sampler</h4>
        <ul
          class="readspeaker-voice-selection"
          [ngClass]="(docTTSControlReq$ | async) ? 'audio-controls-open' : 'audio-controls-closed'"
        >
          <li
            *ngFor="let voice of rsTtsVoices"
            [ngClass]="{
              'voice-selection-opt': (isLoading$ | async) === false,
              'voice-selection-opt-selected': voice.name === (selectedVoice$ | async),
              'voice-selection-disabled': (isLoading$ | async)
            }"
            (click)="onReadVoicePickClick(voice)"
          >
            {{ voice.display }}
          </li>
        </ul>
      </div>
    </ng-template>
  </div>
</ng-container>
<button
  class="tts-controls-button"
  mhe-button
  [purpose]="ButtonPurpose.Icon"
  data-automation-id="read-aloud-voice-button"
  [mheTooltip]="'readspeaker.read_aloud_voice' | translate"
  [placement]="'bottom'"
  (click)="onReadVoiceClick()"
>
  <i class="dpg-icon dpg-icon-cog"></i>
</button>
