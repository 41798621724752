import {
  isLexileLevel,
  LtiParams,
  RuntimeConfiguration,
  TTSGender,
} from '@mhe/reader/models';
import {
  AllowedLevel,
  isAllowedAssignment,
  isAllowedContentWidth,
  isAllowedInterfaceMode,
  isAllowedLevel,
  isAllowedNavigationType,
  isAllowedReadspeakerGenderType,
  isAllowedViewMode,
} from '@mhe/reader/types';

type LTIConfigTranslationMap = {
  [ConfigName in keyof RuntimeConfiguration]: {
    ltiKey: string
    map: (ltiValue: string) => RuntimeConfiguration[ConfigName]
  };
};

const translations: LTIConfigTranslationMap = {
  assignment: {
    ltiKey: 'custom_assignment',
    map: (ltiValue) => {
      const lowered = ltiValue.toLowerCase();
      return isAllowedAssignment(lowered) ? lowered : '';
    },
  },
  assessmentLtiPost: {
    ltiKey: 'custom_assessment_lti_post',
    map: (ltiValue) => ltiValue,
  },
  assessmentLtiPostLaunchParams: {
    ltiKey: 'custom_assessment_lti_post_launch_params',
    map: (ltiValue) => ltiValue,
  },
  assignmentXid: {
    ltiKey: 'custom_assignment_xid',
    map: (ltiValue) => ltiValue,
  },
  assnId: {
    ltiKey: 'custom_assnid',
    map: (ltiValue) => ltiValue,
  },
  authoring: {
    ltiKey: 'custom_authoring',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  backButton: {
    ltiKey: 'custom_back_button',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  cfi: {
    ltiKey: 'custom_cfi',
    map: (ltiValue) => decodeURIComponent(ltiValue),
  },
  clId: {
    ltiKey: 'custom_clid',
    map: (ltiValue) => ltiValue,
  },
  contentType: {
    ltiKey: 'custom_content_type',
    map: (ltiValue) => ltiValue,
  },
  contentWidth: {
    ltiKey: 'custom_content_width',
    map: (ltiValue) => {
      const lowered = ltiValue.toLowerCase();
      return isAllowedContentWidth(lowered) ? lowered : 'default';
    },
  },
  contextId: {
    ltiKey: 'custom_readerx_context_id',
    map: (ltiValue) => ltiValue,
  },
  contextLabel: {
    ltiKey: 'custom_context_label',
    map: (ltiValue) => ltiValue,
  },
  contextMenu2: {
    ltiKey: 'custom_context_menu_2',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  customPartnerId: {
    ltiKey: 'custom_partner_id',
    map: (ltiValue) => ltiValue,
  },
  deliveryScoresheetView: {
    ltiKey: 'custom_delivery_scoresheet_view',
    map: (ltiValue) => ltiValue?.toLowerCase(),
  },
  digitalSample: {
    ltiKey: 'custom_digital_sample',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  displayLevels: {
    ltiKey: 'custom_display_levels',
    map: (ltiValue) => mapContentLevels(ltiValue),
  },
  dfaCategoryUUID: {
    ltiKey: 'custom_category_uuid',
    map: (ltiValue) => ltiValue,
  },
  dfaTopicUUID: {
    ltiKey: 'custom_topic_uuid',
    map: (ltiValue) => ltiValue,
  },
  dfaSearchLaunchSpineID: {
    ltiKey: 'custom_dfa_search_launch_spine_id',
    map: (ltiValue) => ltiValue,
  },
  dfaSearchLaunchIndex: {
    ltiKey: 'custom_dfa_search_launch_index',
    map: (ltiValue) => {
      const index = parseInt(ltiValue, 10);
      if (isNaN(index)) {
        return 0;
      }
      return index;
    },
  },
  dfaSearchLaunchText: {
    ltiKey: 'custom_dfa_search_launch_text',
    map: (ltiValue) => ltiValue,
  },
  fontResizer: {
    ltiKey: 'custom_font_resize',
    map: (ltiValue) => ltiValue.toLowerCase() !== 'false',
  },
  highlights: {
    ltiKey: 'custom_highlights',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  interfaceMode: {
    ltiKey: 'custom_interface_mode',
    map: (ltiValue) => {
      const lowered = ltiValue.toLowerCase();
      return isAllowedInterfaceMode(lowered) ? lowered : 'default';
    },
  },
  lastLocation: {
    ltiKey: 'custom_last_location',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  lastReopen: {
    ltiKey: 'custom_last_reopen',
    map: (ltiValue) => {
      const intval = parseInt(ltiValue, 10);
      if (isNaN(intval)) {
        // TODO: what if the value isn't an integer?
        return 0;
      }
      return intval;
    },
  },
  launchPresentationCssUrl: {
    ltiKey: 'launch_presentation_css_url',
    map: (ltiValue) => ltiValue,
  },
  launchPresentationReturnUrl: {
    ltiKey: 'launch_presentation_return_url',
    map: (ltiValue) => ltiValue,
  },
  launchTimestamp: {
    ltiKey: 'custom_readerx_launch_ts',
    map: (ltiValue) => ltiValue,
  },
  levelDefault: {
    ltiKey: 'custom_level_default',
    map: (ltiValue) => (isAllowedLevel(ltiValue) ? ltiValue : 'on_level'),
  },
  lexileLevelCourse: {
    ltiKey: 'custom_course_lexile_level',
    map: (ltiValue) => (isLexileLevel(ltiValue) ? ltiValue : undefined),
  },
  lexileLevelUser: {
    ltiKey: 'custom_user_lexile_level',
    map: (ltiValue) => (isLexileLevel(ltiValue) ? ltiValue : undefined),
  },
  links: {
    ltiKey: 'custom_links',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  lisOutcomeServiceUrl: {
    ltiKey: 'lis_outcome_service_url',
    map: (ltiValue) => ltiValue,
  },
  lisResultSourcedId: {
    ltiKey: 'lis_result_sourcedid',
    map: (ltiValue) => ltiValue,
  },
  location: {
    ltiKey: 'custom_location',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  navbar: {
    ltiKey: 'custom_navbar',
    map: (ltiValue) => ltiValue.toLowerCase() !== 'false',
  },
  navbarFixed: {
    ltiKey: 'custom_navbar_fixed',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  navigation: {
    ltiKey: 'custom_navigation',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  navigationType: {
    ltiKey: 'custom_navigation_type',
    map: (ltiValue) => {
      const lowered = ltiValue.toLowerCase();
      return isAllowedNavigationType(lowered) ? lowered : 'paginate';
    },
  },
  notes: {
    ltiKey: 'custom_notes',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  overflow: {
    ltiKey: 'custom_overflow',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  pageStartCfi: {
    ltiKey: 'custom_range_start_cfi',
    map: (ltiValue) => formatCfi(ltiValue),
  },
  pageEndCfi: {
    ltiKey: 'custom_range_end_cfi',
    map: (ltiValue) => formatCfi(ltiValue),
  },
  placemarks: {
    ltiKey: 'custom_placemarks',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  platform: {
    ltiKey: 'custom_readerx_platform',
    map: (ltiValue) => ltiValue,
  },
  previewMode: {
    ltiKey: 'custom_preview_mode',
    map: (ltiValue) => ltiValue?.toLowerCase() === 'true',
  },
  print: {
    ltiKey: 'custom_print',
    map: (ltiValue) => ltiValue?.toLowerCase() === 'true',
  },
  rangeStart: {
    ltiKey: 'custom_range_start',
    map: (ltiValue) => ltiValue,
  },
  rangeEnd: {
    ltiKey: 'custom_range_end',
    map: (ltiValue) => ltiValue,
  },
  readiator: {
    ltiKey: 'custom_readiator',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  readspeaker: {
    ltiKey: 'custom_readspeaker',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  readspeakerGender: {
    ltiKey: 'custom_readspeaker_gender', // 'm' or 'f'
    map: (ltiValue) =>
      (isAllowedReadspeakerGenderType(ltiValue)
        ? ltiValue
        : TTSGender.MALE) as TTSGender,
  },
  readspeakerVoicePick: {
    ltiKey: 'custom_readspeaker_voice_picker',
    map: (ltiValue) => ltiValue?.toLowerCase() === 'true',
  },
  resourceLinkId: {
    ltiKey: 'resource_link_id',
    map: (ltiValue) => ltiValue,
  },
  resourceLinkTitle: {
    ltiKey: 'resource_link_title',
    map: (ltiValue) => ltiValue,
  },
  roles: {
    ltiKey: 'roles',
    map: (ltiValue) => ltiValue,
  },
  scrubber: {
    ltiKey: 'custom_scrubber',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  search: {
    ltiKey: 'custom_search',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  sectionXid: {
    ltiKey: 'custom_section_xid',
    map: (ltiValue) => ltiValue,
  },
  singlePageViewOnly: {
    ltiKey: 'custom_single_page_view_only',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  sessionDisabled: {
    ltiKey: 'custom_readerx_session_disabled',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  tagging: {
    ltiKey: 'custom_tagging',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  taggingElements: {
    ltiKey: 'custom_tagging_elements',
    map: (ltiValue) => ltiValue,
  },
  titleOverride: {
    ltiKey: 'custom_title_override',
    map: (ltiValue) => ltiValue,
  },
  toc: {
    ltiKey: 'custom_toc',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  token: {
    ltiKey: 'custom_readerx_token',
    map: (ltiValue) => ltiValue,
  },
  ttsHighlighting: {
    ltiKey: 'custom_tts_highlighting',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  userId: {
    ltiKey: 'custom_readerx_user_id',
    map: (ltiValue) => cleanUserId(ltiValue),
  },
  viewMode: {
    ltiKey: 'custom_view_mode',
    map: (ltiValue) => {
      const lowered = ltiValue.toLowerCase();
      return isAllowedViewMode(lowered) ? lowered : 'digital';
    },
  },
  widgetExpand: {
    ltiKey: 'custom_widget_expand',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  locale: {
    ltiKey: 'launch_presentation_locale',
    map: (ltiValue) => ltiValue,
  },
  oauthConsumerKey: {
    ltiKey: 'oauth_consumer_key',
    map: (ltiValue) => ltiValue,
  },
  elasticSearchEndpoint: {
    ltiKey: 'custom_elastic_search_endpoint',
    map: (ltiValue) => ltiValue,
  },
  elasticSearchSuggestionsEndPoint: {
    ltiKey: 'custom_elastic_search_suggestions_endpoint',
    map: (ltiValue) => ltiValue,
  },
  elasticSearchMinChars: {
    ltiKey: 'custom_elastic_search_min_chars',
    map: Number,
  },
  topicsEndpoint: {
    ltiKey: 'custom_topics_endpoint',
    map: (ltiValue) => ltiValue,
  },
  subjectTopicsEndpoint: {
    ltiKey: 'subject_topics_endpoint',
    map: (ltiValue) => ltiValue,
  },
  orgXid: {
    ltiKey: 'custom_org_xid',
    map: (ltiValue) => ltiValue,
  },
  clientPendoApiKey: {
    ltiKey: 'custom_client_pendo_api_key',
    map: (ltiValue) => ltiValue,
  },
  readerPendoApiKey: {
    ltiKey: 'custom_reader_pendo_api_key',
    map: (ltiValue) => ltiValue,
  },
  pendoClientList: {
    ltiKey: 'custom_reader_pendo_client_list',
    map: (ltiValue) => ltiValue.split(','),
  },
  readerHeightInSeamless: {
    ltiKey: 'custom_reader_height_in_seamless',
    map: (ltiValue) => +ltiValue,
  },
  idmToken: {
    ltiKey: 'custom_user_idm_token',
    map: (ltiValue) => ltiValue,
  },
  darkVersion: {
    ltiKey: 'custom_dark_version',
    map: (ltiValue) => ltiValue.includes('r'),
  },
  epubReleaseUUID: {
    ltiKey: 'custom_epub_release',
    map: (ltiValue: string) => ltiValue,
  },
  epubVersion: {
    ltiKey: 'custom_epub_version',
    map: (ltiValue: string) => ltiValue,
  },
  fallbackToDefaultPublish: {
    ltiKey: 'custom_fallback_to_default_publish',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  isAiAssistEnabled: {
    ltiKey: 'custom_reader_ai_assist_opt_in',
    map: (ltiValue) => ltiValue.toLowerCase() === 'true',
  },
  personXid: {
    ltiKey: 'custom_person_xid',
    map: (ltiValue) => ltiValue,
  },
  aiAssistAudience: {
    ltiKey: 'custom_reader_ai_assist_audience',
    map: (ltiValue) => ltiValue,
  },
};

export function mapLtiToRuntimeConfig(
  ltiParams: LtiParams,
): Partial<RuntimeConfiguration> {
  let populatedConfig = Object.keys(translations).reduce<
  Partial<RuntimeConfiguration>
  >((config, configName: keyof RuntimeConfiguration) => {
    const { ltiKey, map } = translations[configName];
    const ltiValue = ltiParams[ltiKey];

    if (typeof ltiValue === 'string') {
      config = { ...config, [configName]: map(ltiValue) };
    }

    return config;
  }, {});

  const fallbackContextLabel = ltiParams.context_label;
  if (!populatedConfig.contentType && fallbackContextLabel) {
    populatedConfig = { ...populatedConfig, contentType: fallbackContextLabel };
  }

  return populatedConfig;
}

/** helpers */
function formatCfi(ltiValue: string): string {
  const cfi = ltiValue.replace('epubcfi(', '');
  return cfi.substring(0, cfi.length - 1);
}

function cleanUserId(ltiUserId: string): string {
  return ltiUserId
    .replace(/^con-/, '')
    .replace(/^ced-/, '')
    .replace(/^alex-/, '');
}

function mapContentLevels(lti: string): AllowedLevel[] {
  let levels: AllowedLevel[] = [];

  levels = lti
    .split(',')
    .map((level) => level.trim())
    .filter(isAllowedLevel);

  if (levels.length === 0) levels = ['on_level'];

  return levels;
}
