import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { ReaderConfigStore } from '@mhe/reader/components/reader/state';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getReaderApi } from '../state/configuration/configuration.selectors';
import { WINDOW } from '@ng-web-apis/common';
import { DOCUMENT } from '@angular/common';
import { getIntegratorURL } from '../utils/get-integrator-url.function';

@Injectable()
export class ReaderApiLoggingInterceptor implements HttpInterceptor {
  readerIntegratorURL: string | null;

  constructor(private readonly readerConfigStore: ReaderConfigStore,
    private readonly store: Store,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(DOCUMENT) readonly documentRef: Document) {
    this.readerIntegratorURL = getIntegratorURL(windowRef, documentRef);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return of(req.url).pipe(
      withLatestFrom(
        this.store.select(getReaderApi),
        this.readerConfigStore.contextId$,
        this.readerConfigStore.platform$,
        this.readerConfigStore.oauthConsumerKey$,
      ),
      mergeMap(([url, readerApi, contextId, platform, oauthConsumerKey]) => {
        const skip = req.headers.has('X-Skip-Interceptor');
        // Use for requests to reader-api only. Exclude token requests
        if (url.includes(readerApi) && !skip) {
          // Add lti data for logging in backend
          req = req.clone({
            headers: req.headers
              .set('lti-context-id', contextId)
              .set('lti-platform', platform)
              .set('lti-oauth-consumer-key', oauthConsumerKey)
              .set('reader-integrator-url', this.readerIntegratorURL as string),
          });
        }

        return next.handle(req);
      }),
    );
  }
}
