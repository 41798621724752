import { Component } from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { TTSVoice, TTSVoiceProviders } from '@mhe/reader/models';
import { TTSStore } from '../../state/tts.store';
import * as ttsActions from '../../state/tts.actions';
import { take } from 'rxjs/operators';

interface ITTSVoiceProviderSelector {
  name: string
  provider: TTSVoiceProviders
}

@Component({
  selector: 'rdrx-tts-voice-picker',
  templateUrl: './tts-voice-picker.component.html',
  styleUrls: ['./tts-voice-picker.component.scss'],
})
export class TtsVoicePickerComponent {
  showVoiceOptions = false;
  selectedVoice$ = this.ttsStore.voiceName$;
  isLoading$ = this.ttsStore.isLoading$;
  docTTSControlReq$ = this.ttsStore.docTTSControlReq$;
  readonly rsTtsVoices = TTSVoice.allRSVoices();
  readonly azureTtsVoices = TTSVoice.allAzureVoices();
  readonly ButtonPurpose = ButtonPurpose;
  ttsProviderSelected: TTSVoiceProviders = TTSVoiceProviders.RS;

  readonly ttsVoiceProvider: ITTSVoiceProviderSelector[] = [
    {
      name: 'Azure',
      provider: TTSVoiceProviders.AZURE,
    },
    {
      name: 'Readspeaker',
      provider: TTSVoiceProviders.RS,
    },
  ];

  constructor(private readonly ttsStore: TTSStore) {}

  onReadProviderVoicePickClick(voiceProvider: ITTSVoiceProviderSelector): void {
    this.ttsStore.isLoading$.pipe(take(1)).subscribe((isLoading) => {
      if (!isLoading) {
        this.ttsProviderSelected = voiceProvider.provider;
        this.ttsStore.setVoiceProvider(this.ttsProviderSelected);
        this.ttsStore.dispatch(ttsActions.resetVoice);
      }
    });
  }

  onReadVoiceClick(): void {
    this.showVoiceOptions = !this.showVoiceOptions;
  }

  onReadVoicePickClick(voice: TTSVoice): void {
    this.ttsStore.isLoading$.pipe(take(1)).subscribe((isLoading) => {
      if (!isLoading) {
        this.showVoiceOptions = !this.showVoiceOptions;
        this.ttsStore.setVoice(voice);
        this.ttsStore.dispatch(ttsActions.resetVoice);
      }
    });
  }

  protected readonly TTSVoiceProviders = TTSVoiceProviders;
}
