import { TTSMathFlag } from './player-api.model';

export enum TTSVoiceProviders {
  RS,
  AZURE,
}

export interface TTSAzureRawTextRequest {
  text: string
  gender: TTSAzureGender
  voice: string
  // english language only for the POC
  language: 'en-US'
}

export interface TTSAzureRequest {
  content: string
  gender: TTSAzureGender
  language: string
  type: string
  mathFlag?: TTSMathFlag // TODO validate
}

// Value to pass to readspeaker API for successful call. Display for DNN names is edited in the class
export enum TTSVoiceName {
  JAMES = 'James',
  PAUL = 'Paul',
  ASHLEY = 'Ashley',
  BETH = 'Beth',
  JULIE = 'Julie',
  KATE = 'Kate',
  SOPHIE = 'Sophie',
  JAMESDNN = 'James-DNN',
  ERINDNN = 'Erin-DNN',
  JULIEDNN = 'Julie-DNN',
  MARKDNN = 'Mark-DNN',
  MOLLYDNN = 'Molly',
  ROYDNN = 'Roy-DNN',
  SAMUELDNN = 'Samuel-DNN',
  SOPHIEDNN = 'Sophie-DNN',
}

export enum TTSAzureVoiceName {
  ELIZABETH = 'Elizabeth',
  ERIC = 'Eric',
  EMMA = 'Emma',
  JACOB = 'Jacob',
}

export enum TTSGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum TTSAzureGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export class TTSVoice {
  // READSPEAKER VOICES

  static readonly JAMES = new TTSVoice(
    TTSVoiceName.JAMES,
    TTSGender.MALE,
    true,
  );

  static readonly PAUL = new TTSVoice(TTSVoiceName.PAUL, TTSGender.MALE, false);

  static readonly ASHLEY = new TTSVoice(
    TTSVoiceName.ASHLEY,
    TTSGender.FEMALE,
    true,
  );

  static readonly BETH = new TTSVoice(
    TTSVoiceName.BETH,
    TTSGender.FEMALE,
    false,
  );

  static readonly JULIE = new TTSVoice(
    TTSVoiceName.JULIE,
    TTSGender.FEMALE,
    false,
  );

  static readonly KATE = new TTSVoice(
    TTSVoiceName.KATE,
    TTSGender.FEMALE,
    false,
  );

  static readonly SOPHIE = new TTSVoice(
    TTSVoiceName.SOPHIE,
    TTSGender.FEMALE,
    false,
  );

  static readonly JAMESDNN = new TTSVoice(
    TTSVoiceName.JAMESDNN,
    TTSGender.MALE,
    true,
  );

  static readonly ERINDNN = new TTSVoice(
    TTSVoiceName.ERINDNN,
    TTSGender.FEMALE,
    false,
  );

  static readonly JULIEDNN = new TTSVoice(
    TTSVoiceName.JULIEDNN,
    TTSGender.FEMALE,
    false,
  );

  static readonly MARKDNN = new TTSVoice(
    TTSVoiceName.MARKDNN,
    TTSGender.MALE,
    false,
  );

  static readonly MOLLYDNN = new TTSVoice(
    TTSVoiceName.MOLLYDNN,
    TTSGender.FEMALE,
    false,
    '',
    'Child',
  );

  static readonly ROYDNN = new TTSVoice(
    TTSVoiceName.ROYDNN,
    TTSGender.MALE,
    false,
    '',
    'newscaster',
  );

  static readonly SAMUELDNN = new TTSVoice(
    TTSVoiceName.SAMUELDNN,
    TTSGender.MALE,
    false,
  );

  static readonly SOPHIEDNN = new TTSVoice(
    TTSVoiceName.SOPHIEDNN,
    TTSGender.FEMALE,
    false,
  );

  // END READSPEAKER VOICES

  // AZURE VOICES

  static readonly ELIZABETH = new TTSVoice(
    TTSAzureVoiceName.ELIZABETH,
    TTSAzureGender.FEMALE,
    true,
    'en-US-ElizabethNeural',
  );

  static readonly ERIC = new TTSVoice(
    TTSAzureVoiceName.ERIC,
    TTSAzureGender.MALE,
    true,
    'en-US-EricNeural',
  );

  static readonly EMMA = new TTSVoice(
    TTSAzureVoiceName.EMMA,
    TTSAzureGender.FEMALE,
    false,
    'en-US-EmmaMultilingualNeural',
  );

  static readonly JACOB = new TTSVoice(
    TTSAzureVoiceName.JACOB,
    TTSAzureGender.MALE,
    false,
    'en-US-JacobNeural',
  );

  // END AZURE VOICES

  // private to disallow creating other instances of this type
  // access as TTSVoice.JAMES.name
  private constructor(
    public readonly name: TTSVoiceName | TTSAzureVoiceName,
    public readonly gender: TTSGender | TTSAzureGender,
    private readonly defaultVoice: boolean,
    public readonly voiceName?: string,
    private readonly specialText?: string,
  ) {}

  static allRSVoices(): TTSVoice[] {
    return [
      TTSVoice.JAMES,
      TTSVoice.JAMESDNN,
      TTSVoice.ASHLEY,
      TTSVoice.BETH,
      TTSVoice.ERINDNN,
      TTSVoice.JULIE,
      TTSVoice.JULIEDNN,
      TTSVoice.KATE,
      TTSVoice.MARKDNN,
      TTSVoice.MOLLYDNN,
      TTSVoice.PAUL,
      TTSVoice.ROYDNN,
      TTSVoice.SAMUELDNN,
      TTSVoice.SOPHIE,
      TTSVoice.SOPHIEDNN,
    ];
  }

  static allAzureVoices(): TTSVoice[] {
    return [
      TTSVoice.ELIZABETH,
      TTSVoice.ERIC,
      TTSVoice.EMMA,
      TTSVoice.JACOB,
    ];
  }

  get displayName(): string {
    return this.name.replace('-', ' ');
  }

  get display(): string {
    const specialText = this.specialText ? ` (${this.specialText})` : '';
    const defaultVoice = this.defaultVoice ? ' (default)' : '';
    const gender = ` (${this.gender})`;
    return this.displayName + specialText + gender + defaultVoice;
  }
}
