import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import {
  getGlobalToggleSelector,
  getGlobalToggle,
  ToggleIdentifier,
} from '@mhe/ol-platform/global-toggles';
import { BehaviorSubject, Observable, of } from 'rxjs';

class Identifier implements ToggleIdentifier {
  private readonly FEATURE_OWNER = 'mhereaderapi';

  owner: string;
  name: string;

  initialState: boolean;

  subject: BehaviorSubject<boolean>;
  observable: Observable<boolean>;

  outsideObservable: Observable<boolean>;

  constructor(name: string, initialState: boolean) {
    this.owner = this.FEATURE_OWNER;
    this.name = name;

    this.initialState = initialState;
    this.subject = new BehaviorSubject<boolean>(initialState);
    this.observable = this.subject.asObservable();
  }
}

/**
 * Feature Flags for Reader UI
 *
 * This uses Global Toggles from OL Platform.
 * Flags are defined and controlled via Internal Admin Tools.
 * Flags are mapped and given initial state here.
 *
 * When Reader UI is launched this service will start up and
 * call the external service and get the configured state of our
 * flags.
 *
 * Depending on loading speed your component/code may read the
 * initial state rather than the configured state. Keep that in
 * mind when you are wrapping your component/code with a flag.
 *
 * Local overrides are possible:
 *
 * window.overrideGlobalToggle({owner: 'mhereaderapi', name: 'ai_reader_ui', value: true});
 *
 * Local overrides must be executed AFTER Reader UI is loaded and are NOT persisted.
 */
@Injectable()
export class FeatureTogglesService {
  // map of toggles at the external service
  private readonly identifiers: Identifier[] = [
    // short term toggle, features in active development

    // fallback to false if the toggle is not found or cannot be loaded
    new Identifier('foo', false),
    // new Identifier('a11y-group-1', false),
    // new Identifier('a11y-group-2', false),
    // new Identifier('ai-reader/new-thing', false),

    // long term toggle, to allow service-level control

    // fallback to true if the toggle is not found or cannot be loaded
    new Identifier('ai_reader_ui', true),
    // Math in RS
    new Identifier('math_in_rs', true),
    // Maintenance Banner
    new Identifier('maintenance_notification', false),
  ];

  // ready-to-use observables for use in components and templates
  public isFooEnabled$ = this.getObservable('foo');
  public isAiReaderEnabled$ = this.getObservable('ai_reader_ui');
  public isMathInRsEnabled$ = this.getObservable('math_in_rs');
  public isMaintenanceNotificationEnabled$ = this.getObservable('maintenance_notification');

  constructor(
    private readonly store: Store<any>,
  ) {
    // setup the mapped identifiers, results in a cold observable
    this.identifiers.forEach((identifier) => {
      identifier.outsideObservable = store.pipe(
        // select from the global-toggles feature store
        select(getGlobalToggleSelector({ owner: identifier.owner, name: identifier.name })),
        // make use of our defined initial state
        map((toggle) => toggle?.value ?? identifier.initialState),
        // update the subject for observers
        tap((val) => identifier.subject.next(val)),
      );

      // make the observable hot to support initial state
      identifier.outsideObservable.subscribe();

      // trigger the global-toggles action so values are retrieved from the external service
      this.store.dispatch(getGlobalToggle({ owner: identifier.owner, name: identifier.name }));
    });
  }

  public getIdentifier(name: string): Identifier | undefined {
    return this.identifiers.find(identifier => identifier.name === name);
  }

  public getObservable(name: string): Observable<boolean | null> {
    const identifier = this.getIdentifier(name);
    return identifier ? identifier.observable : of(null);
  }
}
