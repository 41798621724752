<mhe-checkbox
    id="quick-annotation"
    data-testid="quick-annotation"
    [checked]="quickAnnotationEnabled$ | async"
    (change)="toggleQuickAnnotation()"
>
    {{ 'context.quick_annotation' | translate }}
</mhe-checkbox>
<span
    role="tooltip"
    class="quick-annotation-info"
    [mheTooltip]="'context.quick_annotation_info' | translate"
    placement="bottom"
    tabindex="0"
>
    <i class="dpg-icon dpg-icon-system-alert-info"></i>
</span>
