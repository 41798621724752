<mat-dialog-content #fontResizerContent class="font-resizer-menu mat-elevation-z8">
  <div class="font-resizer-controls">
    <button
      [attr.aria-label]="'font-resizer.decrease_size' | translate"
      class="btn btn-icon"
      [disabled]="decreaseDisabled$ | async"
      (click)="decrease()"
    >
      A
    </button>

    <mat-slider
      #ngSlider
      class="mhe-a11y rdrx-slider"
      [max]="max"
      [min]="min"
      step="1"
    >
      <input
        matSliderThumb
        #ngSliderThumb="matSliderThumb"
        [formControl]="sliderControl"
        (input)="fontsizeValueChanged({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value })"
        [attr.aria-label]="'font-resizer.slider_label' | translate"
        [attr.aria-valuetext]="'font-resizer.current_value' | translate: { val: ngSliderThumb.value }"/>
    </mat-slider>

    <button
      [attr.aria-label]="'font-resizer.increase_size' | translate"
      class="btn btn-icon"
      [disabled]="increaseDisabled$ | async"
      (click)="increase()"
    >
      A
    </button>
  </div>
</mat-dialog-content>